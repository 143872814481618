.slider-section{
	height: calc(100vh - 147px);
	padding: 10.5vh 0 8vh;
	position: relative;
	background: linear-gradient(to right, $color-primary 61%, #0944E7 60%);
}
.main-slider{
	&__slide{
		&-title{
			max-width: 84%;
			margin-top: 2vh;
			margin-bottom: 5vh;
			color: #fff;
			font-size: 4.6vh;
			line-height: 1.4;
			font-weight: 700;
		}
		&-price{
			margin-top: 10vh;
			font-size: 12vh;
			line-height: 1;
			color: #fff;
			span{
				font-size: 4.7vh;
			}
		}
		&-btn{
			width: 25vh;
			height: 6vh;
			margin-top: 2vh;
		}
		&-img{
			max-height: 50vh;
			max-width: 100%;
			&-mobile{
				display: none !important;
			}
		}
		&-col{
			transition: transform .7s, opacity .7s;
		}
	}
	.slick-slide{
		.main-slider__slide-col{
			opacity: 0;
			transform: scale(.6);
		}
		&.slick-current{
			.main-slider__slide-col{
				opacity: 1;
				transform: scale(1);
			}
		}
	}
	&__controls{
		display: flex;
		align-items: center;
		height: 13vh;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 60vw;
		background-color: #fff;
		&-scroll-btn{
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			height: 100%;
			width: 13vh;
			background-color: #eee;
			border: 0;
			cursor: pointer;
			svg{
				width: 19px;
				height: 21px;
				fill: #AEAEAE;
				animation-duration: 1s;
				animation-iteration-count: infinite;
				// animation-fill-mode: backwards;
				animation-timing-function: linear;
			}
			&:hover{
				svg{
					animation-name: move;
				}
			}
		}
		@keyframes move {
			0%{
				transform: translateY(0);
			}
			33%{
				transform: translateY(3px);
			}
			66%{
				transform: translateY(-3px);
			}
			99%{
				transform: translateY(0);
			}
		}
		&-scroll-text{
			margin-left: 25px;
			font-size: 18px;
			line-height: 1.22;
			color: #737373;
		}
		&-line{
			width: 1px;
			height: 80%;
			margin: 0 50px 0 40px;
			background-color: #D8D8D8;
		}
		&-arrow{
			width: 113px;
			height: 31px;
			border: 0;
			position: relative;
			background: transparent;
			cursor: pointer;
			svg{
				width: 113px;
				height: 31px;
				fill: #D8D8D8;
				overflow: visible;
				transition: fill .4s;
			}
			&-group{
				transform: translateX(-20px);
				transition: transform .4s;
			}
			&.next{
				transform: rotate(180deg);
			}
			&:hover{
				svg{
					fill: $color-primary;
				}
				.main-slider__controls-arrow-group{
					transform: translateX(0);
				}
			}
			&:before{
				content: '';
				width: 16px;
				height: 100%;
				position: absolute;
				top: 0;
				left: -16px;
			}
		}
		&-counter{
			padding-top: 6px;
			margin: 0 27px;
			font-size: 36px;
			line-height: 1;
		}
		.slick-dots{
			display: none !important;
			justify-content: center;
			align-items: center;
			li{
				width: 20px;
				height: 2px;
				margin: 0 5px;
				background-color: #ccc;
				transition: width .4s, background-color .4s;
				button{
					display: none;
				}
				&.slick-active{
					background-color: $color-text;
					width: 40px;
				}
			}
		}
	}
	>.main-slider__slide:not(:first-child){
		display: none;
	}
}

@media (max-width: 1199px){
	.slider-section{
		height: calc(100vh - 60px);
		padding: 10vh 0 6vh;
	}
	.main-slider{
		&__controls{
			width: 80vw;
			&-counter{
				font-size: 3.5vh;
				margin: 0 15px;
			}
			&-arrow{
				width: 90px;
				height: 25px;
				svg{
					width: 90px;
					height: 25px;
				}
			}
		}
		&__slide{
			&-img{
				max-height: 60vh;
			}
		}
	}
}
@media (max-width: 767px){
	.slider-section{
		padding: 6vh 0 6vh;
	}
	.main-slider{
		&__slide{
			&-img{
				max-height: 36vh;
				margin: 10px auto;
			}
			&-title{
				max-width: none;
				margin-top: 0;
				margin-bottom: 3vh;
				font-size: 3.8vh;
			}
			&-price{
				margin-top: 5vh;
				font-size: 7.5vh;
				span{
					font-size: 3vh;
				}
			}
			&-btn{
				width: 26vh;
				height: 7vh;
			}
		}
		&__controls{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100vw;
			height: 7vh;
			&-scroll-btn,&-scroll-text,&-line,&-arrow,&-counter{
				display: none !important;
			}
			.slick-dots{
				display: flex !important;
			}
		}
	}
}