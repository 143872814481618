.company-page{
	background-color: #fff;
	position: relative;
	&__section{
		border-bottom: 1px solid #DEE2E7;
	}
	&__main_section{
		&-img{
			display: block;
			margin: 0 auto;
			max-width: 100%;
			object-position: center;
			object-fit: cover;
		}
	}
	&__track{
		margin: 80px 0 0;
		position: relative;
	}
	&__info_section{
		padding: 0 0 60px;
		&-title{
			margin-bottom: 40px;
		}
		&-list{
			margin-bottom: 50px;
			&-item{
				padding-left: 35px;
				position: relative;
				font-size: 16px;
				line-height: 1.5;
				&:not(:last-child){
					margin-bottom: 20px;
				}
				&:before{
					content: '';
					width: 16px;
					height: 16px;
					position: absolute;
					left: 0;
					top: 2px;
					background: $circle center/cover no-repeat;
				}
			}
		}
		&-img{
			
		}
	}
	&__aside{
		display: flex;
		flex-direction: column;
		width: 38.8vw;
		position: absolute;
		top: -190px;
		bottom: 0;
		right: 0;
		padding: 40px 0 0 36px;
		z-index: 1;
		background-color: #F1F4F7;
		&-title{
			margin-bottom: 100px;
			font-size: 36px;
			line-height: 1.25;
			font-style: italic;
			svg{
				width: 45px;
				height: 27px;
				margin-right: -13px;
				vertical-align: super;
				fill: $color-general;
			}
		}
		&-sidebar_wrap{
			position: relative;
			flex: 1 0 auto;
		}
		.sidebar-menu{
			&-item{
				padding-left: 74px;
			}
			.current-menu-item{
				&:before{
					width: 54px;
				}
			}
		}
	}
	&-section-form{
	}
	&__plans_section{
		padding: 55px 0 60px;
		&-title{
			margin-bottom: 58px;
		}
		&-list{
			position: relative;
			&:before{
				content: '';
				width: 1px;
				position: absolute;
				left: 10px;
				top: 35px;
				bottom: 35px;
				background-color: #C9D2DC;
			}
			&-item{
				display: flex;
				align-items: center;
				position: relative;
				padding-left: 52px;
				&-year{
					margin-right: 30px;
					margin-bottom: -22px;
					color: #C9D2DC;
					font-size: 96px;
					line-height: 1;
					transition: color .4s;
				}
				&-text{
					font-size: 16px;
					line-height: 1.5;
				}
				&:before{
					content: '';
					width: 22px;
					height: 22px;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					background-color: #fff;
					border: 3px solid #C9D2DC;
					border-radius: 22px;
					transition: border-color .4s;
				}
				&:not(:last-child){
					margin-bottom: 70px;
				}
				&.active{
					.company-page__plans_section-list-item{
						&-year{
							color: $color-general;
						}
					}
					&:before{
						border-color: $color-general;
					}
				}
			}
		}
	}
	&__advantages_section{
		padding: 75px 0 0;
		background-color: $color-primary;
		&-title{
			margin-bottom: 54px;
		}
	}
	&__advantage_block{
		margin-bottom: 98px;
		&-icon_wrap{
			height: 70px;
			display: flex;
			align-items: flex-end;
			margin-bottom: 20px;
		}
		&-icon{
			fill: $color-general;
		}
		&-title{
			margin-bottom: 10px;
			font-size: 20px;
			line-height: 1;
			font-weight: 700;
			color: #fff;
		}
		&-text{
			font-size: 16px;
			line-height: 1.5;
			color: #89A8FF;
		}
	}
	&__strategy_section{
		padding: 70px 0 80px;
		&-title{
			margin-bottom: 50px;
		}
	}
	&__strategy_block{
		padding-left: 10px;
		&-title{
			display: inline-block;
			position: relative;
			z-index: 1;
			font-size: 46px;
			line-height: 1;
			color: $color-general;
			&:after{
				content: '';
				height: 41px;
				position: absolute;
				z-index: -1;
				left: -10px;
				right: -10px;
				top: 42%;
				background-color: #F1F4F7;
			}
		}
		&-text{
			position: relative;
			z-index: 1;
			font-size: 16px;
			line-height: 1.5;
		}
		&:not(:last-child){
			margin-bottom: 70px;
		}
	}
	&__partners_section{
		padding: 70px 0 65px;
		&-title{
			margin-bottom: 50px;
		}
	}
	&__partner_block{
		display: block;
		height: 170px;
		padding: 15px 25px;
		margin-bottom: 15px;
		border: 1px solid #E0E0E0;
		&-img{
			width: 100%;
			height: 100%;
			object-fit: scale-down;
			object-position: center;
		}
	}
	&__certificates_section{
		padding: 70px 0 144px;
		&-title{
			margin-bottom: 30px;
		}
	}
	&__certificates_slider{
		width: 100%;
		border: 1px solid #E8EBEF;
		&-wrap{
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			width: 670px;
			max-width: 100%;
			position: relative;
		}
		&-arrow{
			width: 70px;
			height: 70px;
			position: absolute;
			right: -70px;
			border: 0;
			background-color: $color-primary;
			cursor: pointer;
			transition: background-color .4s;
			svg{
				width: 19px;
				height: 11px;
				fill: #fff;
			}
			&.next{
				top: 0;
				svg{
					transform: rotate(-90deg);
				}
			}
			&.prev{
				top: 70px;
				svg{
					transform: rotate(90deg);
				}
			}
			&:hover{
				background-color: $color-general;
			}
		}
		&-counter{
			width: 50px;
			position: absolute;
			bottom: 0;
			left: 102%;
			font-size: 16px;
			line-height: 1;
			color: #757575;
		}
		&-slide{
			height: 420px;
			padding: 30px;
			&-img{
				width: 100%;
				height: 100%;
				object-fit: scale-down;
				object-position: center;
			}
		}
		>.company-page__certificates_slider-slide{
			&:not(:first-child){
				display: none;
			}
		}
	}
}

@media(max-width: 1199px){
	.company-page{
		&__info_section{
			padding: 0 0 30px;
			&-img{
				max-width: 100%;
			}
			&-title{
				margin-bottom: 30px;
			}
			&-list{
				margin-bottom: 35px;
			}
		}
		&__main_section{
			&-img{
				margin: 0;
			}
		}
		&__plans_section{
			padding: 35px 0 40px;
			&-title{
				margin-bottom: 30px;
			}
			&-list{
				&-item{
					max-width: 620px;
					padding-left: 35px;
					&-year{
						margin-right: 15px;
						margin-bottom: -8px;
						font-size: 40px;
					}
					&-text{
						font-size: 15px;
						line-height: 1.3;
					}
					&:not(:last-child){
						margin-bottom: 40px;
					}
				}
				&:before{
					top: 18px;
					bottom: 14px;
				}
			}
		}
		&__aside{
			width: 33vw;
			padding: 40px 0 0 25px;
		}
		&__advantages_section{
			padding: 45px 0 20px;
			&-title{
				margin-bottom: 35px;
			}
		}
		&__advantage_block{
			margin-bottom: 35px;
		}
		&__strategy_section{
			padding: 30px 0 40px;
			&-title{
				margin-bottom: 30px;
			}
		}
		&__strategy_block{
			&:not(:last-child){
				margin-bottom: 35px;
			}
		}
		&__partners_section{
			padding: 35px 0 20px;
		}
		&__certificates_section{
			padding: 35px 0 100px;
			&-title{
				margin-bottom: 25px;
			}
		}
		&__certificates_slider{
			&-arrow{
				position: static;
			}
			&-counter{
				position: static;
				margin-top: 15px;
			}
		}
	}
}

@media (max-width: 991px){
	.company-page{
		&__aside{
			display: none;
		}
		&__track{
			margin: 40px 0 0;
		}
		&__certificates_slider{
			&-slide{
				height: 320px;
				padding: 20px;
			}
			&-wrap{
				margin: 0 auto;
			}
		}
	}
}

@media (max-width: 575px){
	.company-page{
		&__main_section{
			&-img{
				height: 165px;
			}
		}
		&__plans_section{
			&-list{
				&-item{
					align-items: flex-start;
					flex-direction: column;
					&-year{
						margin-bottom: 5px;
					}
					&:before{
						top: 5px;
						bottom: unset;
					}
				}
				&:before{
					top: 15px;
					bottom: 65px;
				}
			}
		}
		&__certificates_slider{
			&-slide{
				height: 200px;
				padding: 10px;
			}
		}
	}
}