.seo-scroller{
	padding: 80px 0 95px;
	background-color: #fff;
	&__image{
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
	&__content{
		height: 385px;
		width: 100%;
		overflow: hidden;
	}
	&__wrap{
		height: 100%;
		display: flex;
		align-items: center;
	}
	.os-content{
		padding: 0 20px 0 0 !important;
	}
	.os-theme-dark > .os-scrollbar{
		&-vertical{
			>.os-scrollbar-track{
				position: absolute;
				right: 2px;
				width: 2px;
				background: transparent !important;
				>.os-scrollbar-handle{
					width: 5px !important;
   					margin: 0px 0px 0px -1px;
   					border-radius: 0 !important;
   					background-color: $color-primary !important;
				}
			}
		}
	}
}

@media (max-width: 1199px){
	.seo-scroller{
		padding: 40px 0 50px;
		&__content{
			height: 270px;
		}
	}
}
@media (max-width : 767px) {
	.seo-scroller{
		padding: 15px 0 40px;
		&__image{
			height: 270px;
			margin-bottom: 30px;
		}
		&__content{
			height: 270px;
		}
		&__wrap{
			margin-right: -15px;
		}
		.os-theme-dark > .os-scrollbar{
			&-vertical{
				>.os-scrollbar-track{
					right: 0;
					>.os-scrollbar-handle{
						width: 3px !important;
					}
				}
			}
		}
	}
}

@media(max-width: 575px){
	.seo-scroller{
		&__image{
			height: 250px;
		}
		&__content{
			height: 250px;
		}
	}
}