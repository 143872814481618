.filters{
	
	&__footer{
		padding: 19px 25px 26px;
		background-color: #fff;
		&-result{
			color: #757575;
			font-size: 15px;
			text-align: center;
			padding-bottom: 29px;
		}
		&-buttons{
			display: flex;
			justify-content: space-between;
			input{
				padding: 0;
				margin-bottom: 5px;
				cursor: pointer;
				height: 30px;
				flex: 0 0 calc(100% / 2 - 5px);
				font-size: 11px;
				letter-spacing: 0.785714px;
			}
			input[type="reset"]{
				font-size: 11px !important;
				color: #757575;
				font-weight: 700;
				letter-spacing: 0.785714px;
				text-transform: uppercase;
				background-color: #F1F4F7;
				border: 0 !important;
				transition: background-color .4s;
				&:hover{
					background-color: #C9D2DC;
				}
			}
		}
	}

	&__mobile-footer{
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100px;
		padding: 16px 12px;
		border-top: 1px solid #e5e5e5;
	}
	&__mobile-result{
		color: #373a3c;
		font-size: 16px;
		font-weight: 400;
		text-align: center;
		padding-bottom: 15px;
	}
	&__mobile-controllers{
		display: flex;
		justify-content: space-between;
		button{
			padding: 0;
			margin-bottom: 5px;
			cursor: pointer;
			height: 30px;
			flex: 0 0 calc(100% / 2 - 5px);
			font-size: 11px;
			letter-spacing: 0.785714px;
		}
	}
	&__mobile-reset{
		font-size: 11px !important;
		color: #757575;
		font-weight: 700;
		letter-spacing: 0.785714px;
		text-transform: uppercase;
		background-color: #F1F4F7;
		border: 0 !important;
		transition: background-color .4s;
		&:hover{
			background-color: #C9D2DC;
		}
	}

	input:not([type="submit"]){
		width: 100%;
		max-width: 100%;
		height: 30px;
		border-radius: 4px;
		border: 1px solid #ccc;
		color: #757575;
		font-size: 15px;
		line-height: 1;
		padding: 0 16px;
		&::placeholder{
			color: #9b9b9b;
			font-size: 14px;
		}
	}
	&__show-hidden{
		display: flex;
		justify-content: center;
		align-items: center;
	    height: 40px;
	    margin-bottom: 4px;
	    vertical-align: middle;
	    background-color: #fff;
	    border: 2px solid #C9D2DC;
	    border-radius: 3px;
	    color: #757575;
	    text-align: center;
	    line-height: 1;
	    cursor: pointer;
	    -webkit-transition: border-color .4s;
	    transition: border-color .4s;
	    &:hover{
	    	color: #757575;
	    	border-color: $color-general;
	    }
	    &:before{
	    	content: 'Показать еще';
	    }
	}
}

.collapse.show+.filters__show-hidden{
	&:before{
		content: 'Скрыть';
	}
}



@media (max-width: 1199px) {
	.filters{
		background-color: #fff;
		position: fixed;
		z-index: 800;
		width: calc(100% - 50px);
		height: 100%;
		right: 0;
		top: 0;
		overflow-y: auto;
		transform: translateX(110%);
		transition: .3s;
		&.opened{
			transform: translateX(0%);
		}
		&__footer{
			display: none;
		}
		&__mobile-wrapper{
			height: calc(100% - 100px);
			overflow-y: auto;
		}
	}
}