.filter-counter{
	width: 125px;
    height: 39px;
    line-height: 35px;
    background-color: $color-primary;
    opacity: .9;
    text-align: center;
    position: absolute;
    right: -109px;
    transition: top .3s;
    display: none;
    z-index: 99;
	text-decoration: none !important;
	border-radius: 3px;
	input{
		background-color: transparent;
		border: none;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 99;
		opacity: 0;
		cursor: pointer;
	}
	span{
		color: #fff;
		font-size: 11px;
		line-height: 1;
		font-weight: 700;
		letter-spacing: 0.785714px;
		text-transform: uppercase;
		border-bottom: 1px dashed;
		padding-bottom: 2px;
		transition: border-color .4s;
	}
	&:after{
		content: '';
		width: 8px;
		height: 8px;
		position: absolute;
		display: block;
		top: 0;
		bottom: 0;
		margin: auto;
		left: -4px;
		z-index: 9;
		background-color: $color-primary;
		transform: rotate(-45deg);
	}
	&:hover{
		span{
			border-color: transparent;
		}
	}
}

@media (max-width: 1199px) {
	.filter-counter {
		display: none !important;
	}
}