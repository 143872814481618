.page {
	padding: 30px 0 90px;
	background-color: #fff;
}


@media (max-width: 767px) {
	.page {
		padding-bottom: 15px;
	}
}