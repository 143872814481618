.catalog-categories {
	&__link{
		display: block;
		font-size: 15px;
		line-height: 1.33;
		color: #757575;
		transition: color .4s;
		&:not(:last-child){
			margin-bottom: 18px;
		}
		&.active{
			color: $color-general;
		}
	}
}