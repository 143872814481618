.catalog-pagination{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	&-button{
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		vertical-align: middle;
		background: #FFFFFF;
		border: 2px solid #C9D2DC;
		border-radius: 3px;
		color: #757575;
		text-align: center;
		line-height: 1;
		cursor: pointer;
		transition: border-color .4s;
		&:hover{
			color: #757575;
			border-color: $color-general;
		}
	}
	&-show-more{
		width: 190px;
		font-size: 11px;
		font-weight: 700;
		letter-spacing: 0.785714px;
		text-transform: uppercase;
	}
	&-anchor{
		width: 70px;
		margin-right: 10px;
		svg{
			width: 15.5px;
			height: 9px;
			transform: rotate(180deg);
			fill: #D8D8D8;
		}
	}
	&-arrow{
		width: 70px;
		svg{
			width: 13.2px;
			height: 7.7px;
			fill: #D8D8D8;
		}
		&.prev{
			svg{
				transform: rotate(90deg);
			}
		}
		&.next{
			svg{
				transform: rotate(-90deg);
			}
		}
		&:not(.active){
			background-color: transparent;
			cursor: default;
		}
	}
	&-link{
		width: 40px;
		padding: 2px 0 0;
	}
	&-item{
		margin: 0 2px;
		&.active{
			.catalog-pagination-button{
				border-color: $color-primary;
			}
		}
	}
	&-left{
		display: flex;
		justify-content: space-between;
	}
}

@media (max-width: 767px){
	.catalog-pagination{
		&-anchor{
			width: 50px;
			display: none;
		}
		&-show-more{
			width: 140px;
		}
		&-arrow{
			width: 50px;
		}
		&-link{
			width: 28px;
		}
		&-button{
			height: 32px;
		}
		&-item{
			display: none;
			&:first-child,&:last-child,&.active,&.visible{
				display: block;
			}
		}
	}
}
@media (max-width: 575px){
	.catalog-pagination{
		&-left{
			width: 100%;
			justify-content: flex-end;
		}
		&-nav{
			width: 100%;
			.pagination{
				margin-top: 15px;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}
		&-item{
			margin: 5px 0;
		}
		&-link{
			width: 32px;
			height: 32px;
		}
		&-arrow{
			&.next{
				float: right;
			}
		}
	}
}