.conditions{
	&__block{
		padding: 24px 25px 30px;
		background-color: #fff;
		&:not(:last-child){
    		margin-bottom: 30px;
		}
		&-title{
			margin-bottom: 20px;
			> svg{
				fill: $color-primary;
				margin-bottom: 14px;
			}
			b{	
				display: block;
				font-size: 20px;
				line-height: 1;
				font-weight: 700;
			}
		}
		&-text{
			margin-bottom: 35px;
			color: #757575;
			font-size: 15px;
			line-height: 1.2;
			p{
				&:not(:last-child){
					margin-bottom: 10px;
				}
			}
		}
		&-link{
			color: $color-general;
			font-size: 11px;
			line-height: 1;
			font-weight: 400;
			letter-spacing: 0.49px;
			text-transform: uppercase;
			border-bottom: 1px solid $color-general;
			transition: border-color .4s;
			&:hover{
				border-color: transparent;
			}
		}
	}
}

@media (max-width : 1199px) {
	.conditions{
		&__block{
			&:not(:last-child){
				margin-bottom: 15px;
			}
		}
	}
}