.catalog-menu, .catalog-submenu {
    position: absolute;
    background: #F1F4F7;
}

.catalog-menu {
    padding: 27px 0;
    z-index: 100;
    top: calc(100% + 20px);
    left: -2px;
    width: 300px;
    background: #F1F4F7;
    border-radius: 3px;
    box-shadow: 0 3px 5px #333;

    &-item {
        $this: &;
        display: block;
        &__text {
            font-size: 16px;
            line-height: 22px;
            color: $color-text;
            border-bottom: 2px solid transparent;
            transition: border-color .4s;
        }

        &__arrow {
            width: 6px;
            height: 9px;
            fill: #999;
            transform: rotate(180deg);
            transition: fill 100ms ease;
        }

        &:not(:last-child){
            margin-bottom: 15px;
        }

        &:hover {

            #{$this}__text {
                border-color: $color-general;
            }

            .catalog-submenu {
                display: block;
            }
        }
    }

    &-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
    }
}

.catalog-submenu {
    display: none;
    z-index: 105;
    top: 0;
    left: 100%;
    width: 870px;
    min-height: 100%;
    padding: 8px 0;
    border-radius: 0 3px 3px 3px;
    box-shadow: 0 3px 5px #333;

    &-link {
        display: block;
        max-width: 290px;
        font-size: 16px;
        line-height: 19px;
        color: $color-text;
        transition: color 100ms ease;
        padding: 8px 25px;

        &:hover {
            color: $color-general;
        }
    }

    &:before{
        content: '';
        width: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -4px;
        border-left: 1px solid #DEDEDE;
        background-color: #F1F4F7;
    }
}
