.account{
	&-sidebar{
		background-color: #F1F4F7;
		&-menu{
			padding: 22px 0;
		}
	}
	&-type{
		padding: 23px 20px 23px 25px;
		position: relative;
		overflow: hidden;
		border-bottom: 1px solid #e5e5e5;
		&:before{
			content: '';
		    width: 26px;
		    height: 26px;
		    position: absolute;
		    left: -13px;
		    top: 16px;
		    border: 5px solid $color-primary;
		    border-radius: 26px;
		}
	}
	&-page{
		.form-group{
		    display: flex;
		    flex-wrap: wrap;
    		margin: 0 0 18px;
    		label{
    			width: 200px;
    			flex: 0 0 auto;
    			margin: 12px 30px 0 0;
    		}
		}
		.form-control{
			&:not(.form-control-short){
				width: 400px;
			}
		}
		&-btn{
			height: 50px;
			width: 300px;
			max-width: 100%;
			margin-top: 30px;
		}
	}
}
@media (max-width: 1199px){
	.account-page{
		.form-group{
			margin: 0 0 14px;
		}
		&-btn{
			margin-top: 10px;
		}
	}
}
@media (max-width: 575px){
	.account{
		&-sidebar{
			margin-bottom: 35px;
		}
	}
	.account-page{
		.form-group{
			label{
				margin: 0 0 5px;
			}
		}
	}
}