.header-contacts {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 18px;

    &-item {
        display: inline-flex;
        align-items: center;
        
        &:not(:first-child){
            margin-left: 40px;
        }

        &__icon {
            fill: $color-general;
            margin-right: 6px;
        }

        &__text {
            padding-bottom: 2px;
            font-size: 16px;
            line-height: 1;
            color: $color-text;
            border-bottom: 2px solid transparent;
            transition: border-color .4s;

            &_email {
                color: #737373;
                border-color: $color-primary;
            }

            &_phone {
                letter-spacing: 0.5px;
                font-weight: 700;
                border-color: $color-general;
            }
        }
        &:hover{
            .header-contacts-item__text{
                border-color: transparent !important;
            }
        }
    }
}
