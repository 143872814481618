.form {
    max-width: 670px;
    max-width: 100%;
    padding: 6px 0 0;

    &-title {
        display: block;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.2;
        border-bottom: 4px solid #F1F4F7;
        padding: 0 0 14px;
        margin: 0 0 30px;
    }

    &-group {
        &__title {
            margin-bottom: 5px;
        }
    }
}
@media(max-width: 1199px){
    .form{
        &-title{
            font-size: 20px;
            padding: 0 0 10px;
            margin: 0 0 20px;
        }
    }
}