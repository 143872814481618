.requisite-block {
	margin: 0 0 37px;
	&__title {
		display: block;
		margin: 0 0 7px;
		color: #94B0FF;
		font-size: 14px;
		line-height: 1;
	}
	&__value {
		display: inline-block;
		color: #fff;
		font-size: 18px;
		line-height: 1.44;
		&-border{
			border-bottom: 2px solid $color-general;
		}
	}
	a.requisite-block__value{
		transition: color .4s;
		&:hover{
			color: $color-general;
		}
	}
}

@media(max-width: 1199px){
	.requisite-block{
		margin: 0 0 20px;
	}
}