.catalog-card{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin-bottom: 3px;
	width: 388px;
	height: 530px;
	background-color: #fff;
	border-right: 3px solid #F1F4F7;
	&-wrap{
		display: flex;
		flex-wrap: wrap;
	}
	&-body{
		padding: 40px 35px 24px;
	}
	&-top{
		display: flex;
		align-items: center;
		margin-bottom: 32px;
	}
	&-icon{
		width: 45px;
		height: 45px;
		flex: 0 0 auto;
		margin-right: 16px;
		margin-bottom: 2px;
		fill: $color-primary;
	}
	&-title{
		font-size: 20px;
		line-height: 1.25;
	}
	&-footer{
		display: block;
		padding: 21px 35px 18px;
		border-top: 1px solid #DDE4E9;
		font-size: 12px;
		line-height: 1;
		font-weight: 700;
		letter-spacing: 0.857143px;
		text-transform: uppercase;
		color: $color-general;
		svg{
			width: 8.5px;
			height: 5px;
			transform: rotate(-90deg);
			margin-left: 8px;
			fill: $color-general;
			transition: transform .4s;
		}
		&:hover{
			svg{
				transform: rotate(-90deg) translateY(4px);
			}

		}
	}
	&-link{
		display: block;
		font-size: 15px;
		line-height: 1.4;
		&:not(:last-child){
			margin-bottom: 18px;
		}
	}

}


@media(max-width: 1199px){
	.catalog-card{
		flex: 0 0 33.3%;
		height: 440px;
		&-body{
			padding: 25px 20px 20px;
		}
		&-top{
			margin-bottom: 20px;
		}
		&-link{
			&:not(:last-child){
				margin-bottom: 12px;
			}
		}
		&-icon{
			width: 38px;
			height: 38px;
			margin-right: 12px;
		}
		&-title{
			font-size: 18px;
		}
		&-footer{
			padding: 16px 20px 13px;
		}
	}
}
@media(max-width: 991px){
	.catalog-card{
		flex: 0 0 50%;
	}
}
@media(max-width: 575px){
	.catalog-card{
		flex: 0 0 100%;
		margin-bottom: 10px;
	}
}