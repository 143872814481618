.sort-parameters{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 9px 20px;
	margin-bottom: 20px;
	background-color: #fff;
	&__order-by{
		margin-right: 19px;
		font-size: 14px;
		line-height: 1;
	}
	&__layout-types{
		display: flex;
		button{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			margin: 0 0 0 5px;
			border-radius: 4px;
			border: 1px solid #e5e5e5;
			background-color: #fff;
			outline: none;
			transition: .3s;
			cursor: pointer;
			&.active{
				border-color: $color-general;
				> svg{
					fill: $color-general;
				}
			}
			> svg{
				transition: .3s;
				fill: #999;
			}
		}
	}
	&-input{
		display: none;
		&:checked+.sort-parameters-label{
			border-color: $color-primary;
		}
	}
	&-label{
		padding: 8px 12px;
		margin: 0 5px 0 0;
		font-size: 14px;
		line-height: 1;
		color: #757575;
		border: 1px solid transparent;
		border-radius: 3px;
		cursor: pointer;
		transition: color .4s;
		svg{
			width: 11px;
			height: 9px;
			margin-right: 4px;
			fill: #757575;
			transition: fill .4s;
			&.flip{
				transform: rotate(0);
			}
		}
		&:hover{
			color: $color-primary;
			svg{
				fill: $color-primary;
			}
		}
	}
}