.section-categories{
	padding: 76px 0 109px;
	background-color: $color-primary;
	overflow: hidden;
	&-title{
		margin-bottom: 50px;
	}
	&__wrap{
		display: flex;
		flex-wrap: wrap;
		max-width: 66%;
		position: relative;
	}
}
.category-block{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 385px;
	height: 250px;
	padding: 40px 35px 35px;
	border-width: 1px 0 0 1px;
	border-style: solid;
	border-color: #4173FF;
	transition: background-color .4s, border-color .4s;
	&:nth-child(2n){
		border-right-width: 1px;
	}
	&:nth-last-child(-n+2){
		border-bottom-width: 1px;
	}
	&__title{
		max-width: 70%;
		font-size: 24px;
		line-height: 1.25;
		color: #fff;
	}
	&__icon{
		align-self: flex-end;
		fill: #4475FF;
		transition: fill .4s;
		&-arrow{
			width: 31px;
			height: 38px;
			transform: rotate(-90deg);
		}
	}
	&__img{
		position: absolute;
		top: -4%;
		left: 99%;
		max-height: 110%;
		z-index: 1;
		visibility: hidden;
		opacity: 0;
		transition: opacity .4s;
	}
	&.active{
		background-color: $color-general;
		border-color: $color-general;
		.category-block__icon{
			fill: #fff;
		}
		.category-block__img{
			visibility: visible;
			opacity: 1;
		}
	}
}

@media(max-width: 1199px){
	.section-categories{
		padding: 50px 0 65px;
		&-title{
			margin-bottom: 30px;
		}
	}
	.category-block{
		height: 215px;
		width: 350px;
		max-width: 50%;
		padding: 17px 25px 19px;
		&__img{
			max-width: 32vw;
		}
		&__title{
			font-size: 18px;
		}
	}
}

@media (max-width: 991px){
	.category-block{
		&__img{
			display: none;
		}
	}
	.section-categories{
		&__wrap{
			max-width: 100%;
		}
	}
}
@media (max-width: 575px){
	.category-block{
		max-width: 100%;
		height: 94px;
		flex-direction: row;
		align-items: center;
		border-right-width: 1px;
	}
}