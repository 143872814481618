.breadcrumbs {
	padding: 23px 0;
	background-color: #F1F4F7;
	ul {
		display: block;
		font-size: 0;
		line-height: 1;
		li {
			display: inline;
			font-size: 14px;
			line-height: 1.3;
			color: #848484;
			a {
				color: #848484;
				transition: color .4s;
				&:hover{
					color: $color-general;
				}
			}
			&:not(:last-child){
				&:after{
					content: '/';
					margin: 0 10px 0 5px;
					color: #848484;
					font-size: 14px;
				}
			}
		}
	}
}

@media(max-width: 767px) {
	.breadcrumbs{
		padding: 14px 0 8px;
		ul{
			li:after{
				margin: 0 3px;
			}
		}
	}
}