.search-form {
    flex: 1 0 auto;
	margin: 0 30px;
	height: 40px;
	position: relative;
	input{
		width: 100%;
		height: 100%;
		padding: 0 0 0 24px;
		font-weight: 300;
		border: 0;
		border-bottom: 1px solid #849CB5;
		background-color: #fff;
		&::placeholder{
			color: #9e9e9e;
			font-weight: 300;
		}
	}
	button{
		display: flex;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 24px;
		height: 100%;
		padding-bottom: 5px;
		border: none;
		background: transparent;
		cursor: pointer;

		> svg{
			fill: $color-text;
		}
	}
	&__ajax-search{
		padding: 10px 20px 0;
		
		> ul{
			display: none;
			border-radius: 4px;
			border: 1px solid #ccc;
			background-color: #fff;
			position: absolute;
			z-index: 200;
			left: 0;
			top: 100%;
			width: 100%;
			max-height: 500px;
			overflow-y: scroll;
			li{
				a, span{
					display: block;
					padding: 8px 20px;
					color: #9b9b9b;
					font-size: 15px;
					font-weight: 600;
					line-height: 21px;
					background-color: #f5f5f5;
					transition: .2s;
					&:hover{
						color: $color-general;
					}
				}
			}

			ul{
				padding: 2px 0;
				li{
					a{
						
						padding: 5px 20px;
						color: #373a3c;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						background-color: transparent;
						&:hover{color: $color-general;}
					}
				}
			}
		}
	}
	&-mobile{
		.search-form__ajax-search{
			padding: 0;
		}
	}
}
