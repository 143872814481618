// Шрифты
$font: 'Vremena Grotesk', sans-serif;

// Цвета
$color-primary: #0039D8;
$color-general: #ED412F;
$color-bootstrap-dark: #0275D8;
$color-text: #0F0F0F;
$color-caption: #999999;
$color-placeholder: #757575;
$color-border: #e5e5e5;
$color-bg: #f5f5f5;

$radius: 8px;

// Время
$time-xs: 100ms;
$time-sm: 200ms;
$time-md: 400ms;
$time-lg: 600ms;
$time-xl: 800ms;


// Отступы
$indent-lg: 1.5rem;
$indent-md: 1rem;
$indent-sm: 0.5rem;
$indent-xs: 0.25rem;
$indent-list: 20px;


$whiteZoomIcon: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMzAgMzAiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yOS42IDI3LjhsLTYuNi02LjVjMi0yLjMgMy4yLTUuMiAzLjItOC40IDAtNy4xLTUuOS0xMi45LTEzLjEtMTIuOS03LjIgMC0xMy4xIDUuOC0xMy4xIDEyLjlzNS45IDEyLjkgMTMuMSAxMi45YzMgMCA1LjgtMSA4LTIuN2w2LjcgNi42Yy4zLjMuNS40LjkuNHMuNy0uMS45LS40Yy41LS42LjUtMS40IDAtMS45em0tMjctMTQuOGMwLTUuNyA0LjctMTAuMyAxMC41LTEwLjNzMTAuNSA0LjYgMTAuNSAxMC4zLTQuNyAxMC4zLTEwLjUgMTAuMy0xMC41LTQuNi0xMC41LTEwLjN6TTE4LjMgMTEuNWgtMy45di0zLjhjMC0uOC0uNS0xLjMtMS4zLTEuM3MtMS4zLjUtMS4zIDEuM3YzLjdoLTMuOGMtLjggMC0xLjMuNS0xLjMgMS4zcy41IDEuMyAxLjMgMS4zaDMuOHYzLjljMCAuOC41IDEuMyAxLjMgMS4zczEuMy0uNiAxLjMtMS4zdi0zLjloMy45Yy43IDAgMS4zLS41IDEuMy0xLjNzLS41LTEuMi0xLjMtMS4yeiIvPjwvc3ZnPg==);

$circle: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjEwNzQyIDE0LjMzQzQuNDYwMDEgMTUuMzc3MSA2LjE1NzMzIDE2LjAwMDIgOC4wMDAxOCAxNi4wMDAyQzEyLjQxODUgMTYuMDAwMiAxNi4wMDAyIDEyLjQxODUgMTYuMDAwMiA4LjAwMDE4QzE2LjAwMDIgNi4xNTczMyAxNS4zNzcxIDQuNDYwMDEgMTQuMzMgMy4xMDc0MkwxMi4zMDgyIDUuMTI5MjRDMTIuODU2OCA1Ljk1MDgxIDEzLjE3NjcgNi45MzgxNCAxMy4xNzY3IDguMDAwMThDMTMuMTc2NyAxMC44NTkxIDEwLjg1OTEgMTMuMTc2NyA4LjAwMDE4IDEzLjE3NjdDNi45MzgxNCAxMy4xNzY3IDUuOTUwODEgMTIuODU2OCA1LjEyOTI0IDEyLjMwODJMMy4xMDc0MiAxNC4zM1oiIGZpbGw9IiMwMDM5RDgiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi42MjExIDEuNDY4ODVDMTEuMzE2IDAuNTQzNzQyIDkuNzIxNDkgMCA4IDBDMy41ODE3MiAwIDAgMy41ODE3MiAwIDhDMCA5LjcyMTQ5IDAuNTQzNzQyIDExLjMxNiAxLjQ2ODg1IDEyLjYyMTFMMy41MTA4IDEwLjU3OTFDMy4wNzM2MSA5LjgxOTgxIDIuODIzNTMgOC45MzkxMSAyLjgyMzUzIDhDMi44MjM1MyA1LjE0MTExIDUuMTQxMTEgMi44MjM1MyA4IDIuODIzNTNDOC45MzkxMSAyLjgyMzUzIDkuODE5ODEgMy4wNzM2MSAxMC41NzkxIDMuNTEwOEwxMi42MjExIDEuNDY4ODVaIiBmaWxsPSIjRUQ0MTJGIi8+Cjwvc3ZnPgo=');