.checkout-form{
	box-shadow: none;
	padding: 0;
	width: 100%;
	transition: .3s;
	&.loading{
		pointer-events: none;
		opacity: 0.5;
	}
	.nav{
		display: inline-flex;
	}
	.nav-tabs{
		border: 0 !important;
		.nav-link{
			padding: 20px 25px 18px;
			margin: 0 -1px 0 0;
			position: relative;
			color: $color-text !important;
			font-size: 16px;
			line-height: 1;
			font-weight: 300;
			border-radius: 0;
			border: 1px solid #C9D2DC !important;
			transition: border-color .4s, text-shadow .4s;
			&.active{
				z-index: 1;
				text-shadow: -1px 0 0;
				border-color: $color-primary !important;
			}
			&:hover{
				z-index: 1;
				border-color: $color-primary !important;
			}
		}
		.nav-item{
			margin: 0;
			border: 0 !important;
			&:first-child{
				.nav-link{
					border-radius: 3px 0px 0px 3px;
				}
			}
			&:last-child{
				.nav-link{
					border-radius: 0px 3px 3px 0px;
				}
			}
		}
		input{
			display: none;
			&:checked+.payment_checkbox{
				z-index: 1;
				text-shadow: -1px 0 0;
				border-color: $color-primary;
			}
		}
	}
	.tab-pane{
		padding: 20px 0;
	}
	.form-group{
		display: flex;
		margin: 0 0 15px;
		label{
			width: 200px;
			margin: 12px 30px 0 0;
			flex: 0 0 auto;
		}
		.form-control{
			&-short{
				width: 230px;
			}
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
	&__form-part{
		margin-bottom: 16px;
		background-color: #fff;
		&-title{
			display: flex;
			justify-content: space-between;
			padding: 25px 40px;
			font-size: 24px;
			font-weight: 700;
			border-bottom: 4px solid #F1F4F7;
			span{
				color: $color-primary;
				padding-right: 2px;
			}
			b{
				font-weight: 700;
			}
			&-message{
				line-height: 1;
				text-align: right;
				p{
					font-size: 14px;
					line-height: 1;
					color: #757575;
					font-weight: 300;
				}
				a{
					font-size: 14px;
					line-height: 1;
					color: #757575;
					font-weight: 300;
					display: inline-block;
					border-bottom: 1px solid #D8D8D8;
					cursor: pointer;
					transition: color .4s;
					&:hover{
						color: $color-general;
					}
				}
			}
		}
		&-body{
			padding: 25px 40px;
		}
	}
	&__payment-type{
		input{
			display: none;
			&:checked+.payment_checkbox{
				z-index: 1;
				text-shadow: -1px 0 0;
				border-color: $color-primary;
			}
		}
		.payment_checkbox{
			padding: 20px 25px 18px;
			margin: 0 -5px 0 0;
			position: relative;
			color: $color-text !important;
			font-size: 16px;
			line-height: 1;
			border-radius: 0;
			border-width: 1px 1px 1px 1px;
			border-style: solid;
			border-color: #C9D2DC;
			cursor: pointer;
			transition: border-color .4s, text-shadow .4s;
			&:first-of-type{
				border-radius: 3px 0px 0px 3px;
			}
			&:last-of-type{
				border-radius: 0px 3px 3px 0px;
			}
			&:hover{
				z-index: 1;
				border-color: $color-primary;
			}
		}
		&-message{
			display: flex;
			margin-top: 28px;
			svg{
				width: 7px;
				height: 32px;
				margin-top: 2px;
				flex: 0 0 auto;

				fill: #C9D2DC
			}
			p{
				max-width: 590px;
				margin-left: 20px;
				color: #757575;
				font-size: 14px;
				line-height: 1.43;
			}
		}
	}
	&__courier-info{
		&-row{
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			border-bottom: 1px dotted #9b9b9b;
			margin-bottom: 10px;
		    padding: 9px 0 0;
			span{
				margin: 0 0 -5px;
				background-color: #fff;
				font-size: 16px;
				font-weight: 400;
				max-width: 45%;
				&:last-child{
					text-align: left;
				}
			}
		}
	}
	&__map-container{
		height: 400px;
		margin: 10px 0 20px;
	}
	&__footer{
		display: flex;
		justify-content: space-between;
		margin-top: 50px;
		.data-warning{
			max-width: 350px;
			margin-top: 8px;
			color: #757575;
			line-height: 1.29;
			a{
				color: #757575;
				&:hover{
					color: #757575;
				}
			}
		}
		.button{
			max-width: 100%;
			input[type="submit"]{
				width: 300px;
				height: 50px;
				max-width: 100%;
			}
			.error_text{
				display: block;
				color: #dc3545;
				font-size: 15px;
				font-weight: 400;
				line-height: 22px;
				padding-top: 10px;
			}
		}
	}
}

.back-to-cart{
	width: 220px;
	height: 42px;
	margin: 0 0 33px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	line-height: 1;
	letter-spacing: 0.49px;
	text-transform: uppercase;
	color: #757575;
	font-weight: 400;
	background-color: #fff;
	border: 2px solid #C9D2DC;
	border-radius: 3px;
	svg{
		width: 28px;
		height: 11px;
		margin-right: 11px;
		margin-bottom: 2px;
		fill: $color-general;
	}
	&:hover{
		color: #757575;
	}
}

@media (max-width: 1199px){
	.checkout-form{
		&__form-part{
			&-body{
				padding: 15px;
			}
			&-title{
				flex-direction: column;
				padding: 20px 15px;
				font-size: 18px;
				&-message{
					margin: 8px 0 0 23px;
					text-align: left;
				}
			}
		}
		&__footer{
			margin-top: 30px;
			.button input[type="submit"]{
				width: 100%;
			}
			.button input[type="submit"]{
				width: 240px;
			}
		}
		.form-group{
			margin-bottom: 14px;
			flex-direction: column;
			label{
				width: auto;
				margin: 0 12px 5px 0;
			}
			.form-control-short{
				width: 100%;
			}
		}
		.nav-tabs{
			flex-direction: row;
			.nav-link{
				padding: 14px 18px 10px;
				font-size: 14px;
			}
		}
		&__payment-type{
			.payment_checkbox{
				padding: 14px 18px 10px;
				font-size: 14px;
			}
		}
	}
}
@media (max-width: 575px){
	.checkout-form{
		.nav{
			width: 100%;
		}
		.nav-tabs{
			flex-direction: column;
			.nav-item{
				&:first-child{
					.nav-link{
						border-radius: 3px 3px 0 0;
					}
				}
				&:last-child{
					.nav-link{
						border-radius: 0 0 3px 3px;
					}
				}
			}
		}
		&__payment-type{
			.payment_checkbox{
				width: 100%;
				padding: 14px 18px 10px;
				font-size: 14px;
				&:first-of-type{
					border-radius: 3px 3px 0 0;
				}
				&:last-of-type{
					border-radius: 0 0 3px 3px;
				}
			}
		}
		&__courier-info{
			&-row{
				span{
					flex: 0 0;
				}
			}
		}
		&__footer{
			flex-direction: column;
			.button input[type="submit"]{
				width: 100%;
			}
			.data-warning{
				margin-bottom: 15px;
			}
		}
	}
}