.section-partners{
	padding: 57px 0 90px;
	overflow: hidden;
	background-color: #fff;
	&-title{
		margin-bottom: 30px;
	}
	&-decor{
		width: 454px;
		height: 458px;
		position: relative;
		svg{
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		img{
			max-height: 103%;
			max-width: 100%;
			margin-left: 8%;
			position: relative;
			z-index: 1;
		}
	}
}
.partners-row{
	display: flex;
	flex-wrap: wrap;
}
.partner-block{
	height: 90px;
	display: flex;
	align-items: center;
	flex: 0 0 25%;
	margin-top: 50px;
	&-img{
		max-width: 75%;
		max-height: 80%;
		filter: grayscale(100%);
		opacity: .75;
	}
}

@media(max-width: 1199px){
	.section-partners{
		padding: 30px 0 90px;
		&-title{
			margin-bottom: 0;
			font-size: 20px;
		}
		&-decor{
			width: 350px;
			height: 350px;
		}
	}
	.partner-block{
		height: 60px;
		flex: 0 0 33%;
		margin-top: 25px;
	}
}
@media(max-width: 767px){
	.partner-block{
		height: 70px;
		width: 50%;
		margin-top: 5px;
		flex: 0 0 50%;
	}
	.partners-row{
		justify-content: space-between;
	}
}