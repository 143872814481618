.offer-block{
	display: flex;
	flex-direction: column;
	height: calc(100% - 30px);
	margin-bottom: 30px;
	&__top{
		display: block;
		height: 280px;
		position: relative;
		background-color: $color-primary;
	}
	&__time{
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
		padding: 14px 22px 10px;
		color: $color-general;
		font-size: 16px;
		line-height: 1;
		font-weight: 700;
		background-color: #F1F4F7;
	}
	&__img{
		width: 100%;
		height: 100%;
		padding: 22px;
		object-position: center;
		object-fit: scale-down;
		background-color: $color-primary;
	}
	&__bottom{
		display: block;
		padding: 20px 20px 30px;
		flex: 1 0 auto;
		background-color: #F1F4F7;
	}
	&__title{
		display: block;
		margin-bottom: 30px;
		font-size: 22px;
		line-height: 1.45;
	}
	&__link{
		color: $color-general;
		font-size: 11px;
		line-height: 1;
		letter-spacing: 0.49px;
		text-transform: uppercase;
		border-bottom: 1px solid $color-general;
		transition: color .4s, border-color .4s;
	}
	&:hover{
		color: $color-text;
		.offer-block__link{
			color: $color-primary;
			border-color: transparent;
		}
	}
}

@media(max-width: 1199px){
	.offer-block{
		margin-bottom: 20px;
		height: calc(100% - 20px);
		&__title{
			margin-bottom: 15px;
			font-size: 20px;
			line-height: 1.3;
		}
		&__bottom{
			padding: 15px 20px 20px;
		}
	}
}