.catalog-page{
	padding: 19px 0 90px;
	.count_products{
		color: #90959A;
		font-size: 24px;
		font-weight: 300;
	}
}
@media (max-width: 1199px){
	.catalog-page{
		.count_products{
			font-size: 18px;
		}
	}
}