.product-amount {
    display: flex;
    justify-content: center;
    width: 100px;
    height: 30px;

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 100%;
        color: $color-primary;
        font-size: 20px;
        line-height: 1;
        font-weight: 300;
        transition: background-color .4s;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
        background-color: #F1F4F7;

        &:hover {
            background-color: #C9D2DC;
        }
    }

    &__field {
        display: flex;
        width: 40px;

        input {
            border: none;
            width: 100%;
            text-align: center;
            font-size: 16px;
            line-height: 1;
            font-weight: 300;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}
