.login-form{
	max-width: 365px;
	position: relative;
	.custom_modal__footer{
		&:before{
			left: 0;
			right: 0;
		}
	}
	&-submit{
		height: 50px;
		padding-left: 30px;
		padding-right: 30px;
	}
	&-long{
		max-width: 560px;
	}
	&-checkboxes{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 40px;
		.payment_checkbox{
			flex: 1 0 33%;
			margin: 0 -1px 0 0;
			text-align: center;
		}
	}
}
@media (max-width: 575px){
	.login-form{
		&-checkboxes{
			.payment_checkbox{
				flex: 1 0 100%;
			}
		}
		.forgot-password{
			display: inline-block;
			margin-left: 0;
			margin-top: 12px;
		}
	}
}