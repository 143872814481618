.form-group{
	margin: 0 0 18px;
	&.required{
		>label{
			position: relative;
			&:after{
				content: '*';
				color: $color-general;
			}
		}
	}
	label{
		margin: 0 0 5px;
		font-size: 14px;
		line-height: 1;
	}
	textarea{
		height: 135px;
	}
}
.form-control{
	padding: 4px 15px 0;
	font-weight: 300;
	font-size: 16px;
	border: 1px solid #C9D2DC;
	border-radius: 3px;
	&-short{
		width: 230px;
	}
}