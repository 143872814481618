.advantages-section{
	padding: 80px 0 94px;
	overflow: hidden;
	background-color: #fff;
	&-title{
		display: inline-block;
		padding: 12px 10px 8px;
		font-size: 16px;
		line-height: 1;
		font-weight: 700;
		letter-spacing: 1.14286px;
		text-transform: uppercase;
		color: #fff;
		background-color: $color-general;
	}
}
.advantages-slider{
	margin: 77px 0 0;
	display: flex;
	.slick-slide{
		transition: opacity .4s;
		&:not(.slick-current){
			opacity: .24;
		}
	}
	>.advantages-slider__slide:not(:first-child){
		opacity: .24;
	}
	.slick-list{
		width: 100%;
		overflow: visible;
	}
	&__slide{
		height: 325px;
		margin-right: 80px;
		flex: 1 0 auto;
		&-title{
			margin: 0 80px 0 0;
			font-size: 100px;
			line-height: 1;
			font-weight: 400;
		}
		&-icon{
			flex: 0 0 auto;
			margin-right: 40px;
			fill: $color-primary;
		}
		&-text{
			max-width: 360px;
			font-size: 20px;
			line-height: 1.5;
		}
		&-decor{
			width: 34px;
			height: 34px;
		}
	}

	&__flex-wrap{
		display: flex;
	}
	&__title-wrap{
		margin-bottom: 48px;
		align-items: center;
	}
	&__arrow{
		display: inline-block;
		width: 56px;
		height: 31px;
		background: transparent;
		border: 0;
		cursor: pointer;
		&.next{
			transform: rotate(180deg);
		}
		&-overlay{
			width: 55px;
			height: 31px;
			position: relative;
			z-index: 1;
			background-color: #fff;
		}
		svg{
			fill: #D8D8D8;
			width: 113px;
			height: 31px;
			transition: transform .4s, fill .4s;
		}
		&:hover{
			svg{
				transform: translateX(-56px);
				fill: $color-primary;
			}
		}
	}
}

@media(min-width: 1200px){
	.advantages-slider{
		
	}
}

@media (max-width: 1199px){
	.advantages-section{
		padding: 45px 0;
		&-title{
			transform-origin: left top;
			transform: scale(.8);
		}
	}
	.advantages-slider{
		margin: 40px 0 0;
		&__slide{
			width: 420px !important;
			height: auto;
			padding-bottom: 20px;
			margin-right: 55px;
			&-title{
				margin: 0 32px 0 0;
				font-size: 36px;
			}
			&-decor{
				width: 20px;
				height: 20px;
			}
			&-text{
				font-size: 18px;
				line-height: 1.35;
			}
			&-icon{
				margin-right: 20px;
			}
		}
		&__title-wrap{
			margin-bottom: 32px;
		}
		&__arrow{
			svg{
				width: 78px;
				height: 22px;
			}
			&:hover{
				svg{
					transform: translateX(-21px);
				}
			}
		}
	}
}
@media(max-width: 767px){
	.advantages-slider{
		&__slide{
			width: 360px !important;
			margin-right: 45px;
			&-icon{
				margin-bottom: 15px;
			}
		}
		&__text-wrap{
			flex-direction: column;
		}
		&__controls{
			justify-content: space-between;
		}
		&__arrow{
			overflow: hidden;
			&-overlay{
				display: none;
			}
			&:hover{
				svg{
					transform: translateX(0);
				}
			}
		}
	}
}
@media(max-width: 575px){
	.advantages-slider{
		&__slide{
			width: 290px !important;
			margin-right: 15px;
			&-decor{
				display: none;
			}
		}
	}
}