.compare-component{
	&.empty{
		.compare-component{
			&__notification{
				display: flex;
			}
		}
	}
	&__notification{
		display: none;
	}
	&__categories{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 0 10px;
		&-item{
			display: block;
			width: 100%;
			padding: 14px 18px 10px;
		    color: #0F0F0F;
		    font-size: 14px;
		    line-height: 1.15;
		    font-weight: 300;
		    border-radius: 0;
		    border: 1px solid #C9D2DC;
		    -webkit-transition: border-color .4s, font-weight .4s;
		    transition: border-color .4s, font-weight .4s;
			&.active{
				border-color: $color-primary;
				font-weight: 700;
			}
			&:first-child{
				border-radius: 3px 3px 0 0;
			}
			&:last-child{
				border-radius: 0 0 3px 3px;
			}
		}
	}
	&__product-scroller-wrapper{
		
	}
	&__controlls{
		padding: 20px;
		background-color: #fff;
	}
	&__product-scroller{
		display: flex;
		padding: 0 0 35px;
		height: 475px;
		&:not(.os-host){
			visibility: hidden;
		}
		.os-content{
			display: flex;
			margin: 0 0 20px;
		}
		.product-card{
			margin-right: 15px;
			height: auto;
			flex: 0 0 auto;
		}
	}
	&__params-wrapper{
		padding: 15px 0 0;
	}
	&__params-table{
		padding: 5px 0;
	}
	.product-card-btn{
		width: 100%;
	}
}

@media (max-width : 1199px){
	.compare-component{
		&__product-scroller{
			height: auto;
			.os-content{
				flex-direction: row;
			}
			.product-card{
				margin-right: 10px;
			}
		}
	}
}

@media (max-width : 991px) {
	.compare-component{
		
		&__product-scroller{
			padding: 0 0 35px;
			height: auto;
		}
		&__controlls{
			margin-bottom: 20px;
		}
	}
}

@media (max-width : 767px) {
	
}


@media (max-width : 565px) {
	.compare-component{
		&__product-scroller-wrapper{
			
		}
		&__product-scroller{
			padding: 0 0 25px;
		}
		&__params-wrapper{
			padding: 5px 0 0;
		}
	}
}
