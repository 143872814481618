.range-slider-field{
	position: relative;
	&__inputs{
		display: flex;
		justify-content: space-between;
		position: relative;
		&:after{
			content: '';
			width: 20px;
			height: 1px;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			background-color: #D8D8D8;
		}
	}
	&__min-price, &__max-price{
		width: 90px !important;
		&::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
			-webkit-appearance: none;
			margin: 0; 
		}
	}
	
}
.irs{
	height: 22px;
	margin-bottom: 20px;
	&-min, &-max, &-from, &-to, &-single{
		display: none;
	}
	&-line{
		top: 9px;
		border-radius: 2px;
		background: #D1DBE4;
		height: 4px;
		border: none;
	}
	&-bar{
		top: 9px;
		height: 4px;
		border-radius: 2px;
		background: #9EB8D2;
		border: none;
	}
	&-slider{
		top: 0;
		width: 22px;
		height: 22px;
		border: 5px solid $color-general;
		background: #fff;
	}
}