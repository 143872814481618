.extra-products-slider{
	padding: 70px 0 50px;
	background-color: #E5EAEF;
	&__slider-header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;
		&-title{
			font-size: 24px;
			font-weight: 700;
		}
		&-link{
			color: #97A1AA;
			font-size: 14px;
			line-height: 1;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;
			border-bottom: 2px solid $color-primary;
			transition: border-color .4s;
			&:hover{
				color: #97A1AA;
				border-color: transparent;
			}
		}
	}
}


@media(max-width: 1199px){
	.extra-products-slider{
		padding: 35px 0 20px;
		&__slider-header{
			&-title{
				font-size: 22px;
			}
		}
	}
}
@media (max-width: 575px){
	.extra-products-slider{
		&__slider-header{
			flex-direction: column;
			align-items: flex-start;
			&-title{
				margin-bottom: 12px;
			}
		}
	}
}