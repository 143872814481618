.catalog-button {
    $this: &;
    padding: 5px 18px 0 48px;
    position: relative;
    width: 220px;
    height: 45px;
    border-radius: 3px;
    border: 2px solid $color-general;
    transition: background-color .4s;
    &:hover{
        background-color: $color-general;
        .catalog-button-link{
            color: #fff;
        }
        .catalog-button-icon{
            fill: #fff;
            &-plus{
                opacity: 0;
            }
            &-arrow{
                opacity: 1;
            }
        }
    }
    &-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        font-size: 14px;
        letter-spacing: 0.7px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        color: $color-general;
        transition: color .4s;
    }

    &-lines {
        display: inline-block;
		position: relative;
		bottom: 1px;
		height: 2px;
		width: 16px;
        background-color: #fff;
        margin-right: 12px;
        transform: translateY(1px);
		&:after, &:before{
			content:"";
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #fff;
		}
		&:before{
			top: -6px;
		}
		&:after{
			bottom: -6px;
		}
    }

    &-arrow {
        display: inline-block;
        border: 4px solid transparent;
        border-top: 5px solid #fff;
        margin-left: 12px;
        transform: translateY(3px);
    }

    &-icon{
        fill: $color-general;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 18px;
        margin: auto;
        transition: opacity .4s,fill .4s;
        &-plus{
            width: 16px;
            height: 16px;
        }
        &-arrow{
            width: 14px;
            height: 17.5px;
            opacity: 0;
        }
    }

    &:after{
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: -20px;
        height: 20px;
    }

    .catalog-menu {
        display: none;
    }

    &:hover {
        .catalog-menu {
            display: block;
        }
    }
}