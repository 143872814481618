.page-title {
	display: block;
	margin-bottom: 30px;
	font-size: 36px;
	font-weight: 700;
	line-height: 1.22;
    &-white{
        color: #fff;
    }
}

.catalog-page-title{
	
}

@media (max-width: 1199px) {
	.page-title {
		margin-bottom: 20px;
		font-size: 26px;
	}
}
