.footer {
    flex: 0 1 auto;
    border-top: 2px solid #C9D2DC;
	&-block{
		margin-left: -4px;
		&__title{
			display: block;
			margin-bottom: 32px;
			font-size: 20px;
			line-height: 1;
			font-weight: 700;
		}
		&__menu{
			li{
				line-height: 1;
				a{
					font-size: 15px;
					line-height: 1.33;
					color: #676767;
				}
				&:not(:last-child){
					margin-bottom: 13px;
				}
			}
		}
		&__req{
			display: inline-block;
			font-size: 18px;
			line-height: 1.2;
			&_mail{
				border-bottom: 1px solid #D8D8D8;
			}
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
		&__sub-title{
			margin-bottom: 7px;
			font-size: 12px;
			line-height: 1;
			color: #999;
		}
		a{
			transition: color .4s;
			&:hover{
				color: $color-general;
			}
		}
		&__menu li a, 
		&__req span, 
		&__req a{
		}
	}
	&-callback{
		margin: 30px 0 0;
	}
	&__lower-part{
		border-top: 1px solid #D8D8D8;
		&-wrapper{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			height: 80px;
			margin-left: -4px;
		}
		&-left{
			display: flex;
			align-items: center;
			p{
				padding-right: 38px;
			}
			a{
				text-decoration: underline;
				transition: color .4s;
				&:hover{
					color: $color-general;
					text-decoration: none;
				}
			}
			p, a{
				display: block;
				color: #A3A3A3;
				font-size: 14px;
			}
		}
	}
	&__upper-part{
		padding: 60px 0 50px;
	}
	&__made-by{
		display: flex;
		align-items: center;
		color: #999;
		font-size: 14px;
		font-weight: 400;
		transition: .3s;
		> svg{
			fill: #9b9b9b;
			margin-left: 5px;
			transition: .3s;
		}
		&:hover{
			color: $color-general;
			> svg{
				fill: $color-general;
			}
		}
	}
}




@media (max-width: 1199px){
	.footer{
		&-block{
			margin-left: 0;
			&__title{
				margin-bottom: 15px;
			}
			&__sub-title{
				margin-bottom: 4px;
			}
		}
		&__upper-part{
			padding: 40px 0 30px;
		}
		&__lower-part{
			&-wrapper{
				margin-left: 0;
			}
		}
	}
}

@media (max-width : 991px) {
	.footer{
		&__lower-part{
			&-wrapper{
				height: auto;
				flex-direction: column;
				justify-content: center;
				padding: 10px 0;
			}
			&-left{
				flex-direction: column;
				p, a{
					padding: 4px 0;
					text-align: center;
				}
			}
		}

		&__upper-part{
			padding: 30px 0 20px;
		}

		&-block{
			padding: 15px 0;
		}
		
		&__made-by{
			padding: 4px 0;
			justify-content: center;
		}
	}
}

@media (max-width : 767px) {
	.footer{
		
	}
}