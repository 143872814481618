.section-form{
	padding: 68px 0 79px;
	background-color: $color-primary;
	&-phone{
		display: block;
		max-width: 100%;
		margin: -106px 0 0 0;
		filter: drop-shadow(0px 51px 54px rgba(0, 0, 0, 0.101344));
	}
	&-title{
		margin-bottom: 3px;
	}
	&-text{
		margin-bottom: 30px;
		font-size: 18px;
		line-height: 1.56;
		color: #fff;
	}
	&-data-warning{
		flex: 1 0 100%;
		color: #4B78F6;
		a{
			color: #4B78F6;
		}
	}
	&-form{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		max-width: 430px;
		position: relative;
		.form-group{
			width: 170px;
			height: 60px;
			margin: 0 10px 0 0;
		}
		.form-control{
			height: 100%;
			width: 100%;
			padding: 20px 10px 20px 15px;
			color: $color-text;
			font-size: 14px;
			line-height: 1;
			border-color: transparent;
			border-radius: 3px;
			&.is-invalid{
				border-color: $color-general;
			}
			&::placeholder{
				font-size: 16px;
				color: #ADADAD;
				font-weight: 300;
			}
		}
		.btn{
			height: 60px;
			flex: 1 0 auto;
		}
		&.load,&.success{
			>:not(.load):not(.success){
				opacity: 0;
			}
		}
		.load,.success{
			background-color: transparent;
		}
		.invalid-feedback{
			margin-top: 2px;
			color: $color-general;
			font-size: 12px;
			line-height: 1;
		}
	}
}

@media(max-width: 1199px){
	.section-form{
		padding: 38px 0 45px;
		&-text{
			font-size: 16px;
			line-height: 1.5;
		}
		&-form{
			.btn{
				font-size: 16px;
				font-weight: 500;
				letter-spacing: 2px;
			}
		}
		&-phone{
			max-width: 90%;
			margin: -90px 0 0;
		}
	}
}

@media(max-width: 991px){
	.section-form{
		&-form{
			.form-group{
				width: 100%;
				margin: 0 0 10px 0;
			}
		}
		&-phone{
			margin: 0;
		}
	}
}

@media (max-width: 767px){
	.section-form{
		padding-bottom: 46px;
		&-phone{
			width: 100%;
			max-width: 320px;
			margin: -100px auto 40px;
		}
		&-title{
			text-align: center;
		}
		&-text{
			max-width: 255px;
			margin: 0 auto 30px;
			text-align: center;
		}
		&-data-warning{
			text-align: center;
		}
		&-form{
			max-width: 290px;
			margin: 0 auto;
		}
	}
}