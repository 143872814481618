.burger {
    $this: &;
    position: relative;
    width: 26px;
    height: 14px;

    &-lines {
        position: relative;
        width: 100%;
        height: 100%;

        &__top, &__center, &__bottom {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 2px;
            background-color: $color-text;
            margin: auto 0;
            transition: transform 200ms ease, opacity 200ms ease;
        }
        
        &__center{
            width: 100%;
            left: 0;
        }

        &__top, &__bottom{
            width: 66%;
        }

        &__top {
            transform: translateY(-8px);
            right: 0;
        }

        &__bottom {
            transform: translateY(8px);
            left: 0;
        }
        &-circle{
            width: 14px;
            height: 14px;
            position: absolute;
            top: -10px;
            left: -3px;
            z-index: 1;
            transform: scale(0);
            font-size: 0;
            background-color: $color-general;
            border: 3px solid #fff;
            border-radius: 14px;
            transition: transform .2s;
            &.filled{
                transform: scale(1);
            }
        }
    }

    .mobile-menu-button.opened &-lines {
        &__top {
            transform: translateY(0) rotate(45deg);
        }

        &__center {
            transform: translateX(-5px) scale(0.8);
            opacity: 0;
        }

        &__bottom {
            transform: translateY(0) rotate(-45deg);
        }
        &__top, &__bottom{
            width: 100%;
        }
    }
}