// General fonts
@mixin font-h1 {
	font-size: 36px;
	font-weight: 700;
	line-height: 1.22;
}

@mixin font-h2 {
	font-size: 30px;
	font-weight: 700;
	line-height: 1.21;
}

@mixin font-h3 {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.44;
}

@mixin font-p {
	font-size: 16px;
	line-height: 1.62;
}

@mixin font-sign {
	font-size: 14px;
}



