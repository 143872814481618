* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    min-height: 100%;
    height: 100%;
}

body {
    min-width: 320px;
    height: 100%;
    margin: 0;
    padding: 0;
    color: $color-text;
	font-family: 'Vremena Grotesk', sans-serif;
    font-weight: 300;
	&.opened {
		// position: fixed;
		// left: 0;
		// right: 0;
		// top: 0;
		overflow: hidden;
	}
}

::-webkit-input-placeholder { color: $color-placeholder; }
::-moz-placeholder          { color: $color-placeholder; }
:-moz-placeholder           { color: $color-placeholder; }
:-ms-input-placeholder      { color: $color-placeholder; }

ol, ul {
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

.h1, .h2, .h3, .h4, h1, h2, h3, h4, p {
    line-height: normal;
    margin: 0;
    padding: 0;
}

a {
    color: $color-text;
    text-decoration: none;
    &:hover {
        color: $color-general;
        text-decoration: none;
    }
}

a:focus, button:focus, input:focus, textarea:focus {
    outline: 0;
    text-decoration: none;
}

// Базовая обертка сайта
.base {
    &-wrapper, &-container {
        width: 100%;
    }
    
    &-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        padding-top: 147px;
        background-color: #F1F4F7;
    }

    &-container {
        flex: 1  auto;
        overflow: initial;
    }

    &-page {
        padding-bottom: 90px;
    }
}

.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.navbar {
    padding: 0;
}

.params-item~.params-delimeter {
    display: none;
}

.params-delimeter::before {
    content: '-';
}


//  
.mobile-search-trigger{
	svg{
		pointer-events: none;
	}
}
.nav-close{
	*{
		pointer-events: none;
	}
}



///  Bootstrap reset
.btn{
    display: inline-flex;
    padding: 4px 0 0;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 0;
    transition: background-color .4s;
    &:hover{
        background-color: #E22815;
    }
    &-alt{
        padding: 3px 0 0;
        border: 2px solid #C9D2DC;
        border-radius: 3px;
        font-size: 11px;
        color: #757575;
        font-weight: 400;
        letter-spacing: 0.49px;
        transition: border-color .4s;
        svg{
            fill: #757575;
        }
        &:hover{
            background-color: #fff;
            border-color: $color-primary;
        }
        &.active{
            border-color: $color-primary;
        }
    }
}
.btn-primary{
	background-color: $color-general;
    border-color: $color-general;
    border-radius: 3px;
    line-height: 1;
    &:disabled{
        background-color: $color-primary;
        border-color: $color-primary;
    }
}
.page-item.active .page-link{
	background-color: $color-bootstrap-dark;
}
.btn-outline-primary,
.btn-primary:hover,
.btn-outline-primary:hover,
.page-item.active .page-link{
	border-color: $color-bootstrap-dark;
}
.btn-outline-primary,
.page-link,
.page-link:hover{
	color: $color-bootstrap-dark;
}
.btn.disabled, .btn:disabled{
	opacity: 1;
}






@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

@media (max-width: 1199px) {
    .container {
        width: 100%;
        max-width: 100%;
    }
    body{
        padding-top: 0 !important;
    }
    .btn{
        font-size: 11px;
        letter-spacing: 0.79px;
    }
    .base{
        &-wrapper{
            padding-top: 60px;
        }
    }
}

