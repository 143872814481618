.quote {
	position: relative;
	border-left: 5px solid $color-primary;
	padding: 21px 40px 21px 35px;
	margin: 20px 0 10px;
	background-color: #f8f8f8;
	&-text {
		@include font-p;
	}
}

@media(max-width: 767px) {
	@import "../media-mixins";
	.quote {
		padding: 5px 10px;
		margin: 10px 0;
		&::before, &::after {
			width: 26px;
			height: 24px;
			background-size: 14px 12px;
		}
		&::before {
			top: -13px;
			left: -11px;
		}
		&::after {
			right: -11px;
			bottom: -13px;
		}
		&-text {
			@include font-p;
		}
	}
}