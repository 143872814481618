.product-price{
	margin-top: 15px;
	&__price{
		font-size: 30px;
		line-height: 1;
		&.has-old{
			color: $color-general;
		}
	}
	&__old_price{
		margin-top: 9px;
		color: #BABABA;
		font-size: 18px;
		line-height: 1;
		text-decoration: line-through;
	}
}
@media (max-width: 1199px){
	.product-price{
		&__price{
			font-size: 18px;
		}
		&__old_price{
			font-size: 15px;
		}
	}
}