.page_404{
	background-color: #fff;
}
.wrapper-404{
    text-align: center;
	padding: 34px 0;
	&__error-code{
		color: #ccc;
		font-size: 170px;
    	line-height: 200px;
		align-items: center;
	}
	&__title{
		display: block;
		color: $color-text;
		font-size: 30px;
		font-weight: 600;
		line-height: 52px;
	}
	&__desc{
		display: block;
		color: $color-text;
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
	}
	&__to-index{
		margin: 34px 0 0;
		width: 240px;
		height: 50px;
		max-width: 100%;
	}
}
@media (max-width: 991px){
	.page_404{
		padding: 50px 0;
	}
}

@media (max-width : 767px) {
	.wrapper-404{
		padding: 20px 0 0;
		&__title{
			font-size: 26px;
    		line-height: 44px;
		}
		&__to-index{
			margin: 20px 0 30px;
		}
		&__error-code{
			font-size: 130px;
    		line-height: 110px;
		}
	}
}