.product-form{
	padding: 0 0 40px;
	border-bottom: 1px solid #fff;
	&__title{
		margin: 0;
		font-size: 26px;
	}
	&__vendor-code{
		margin-bottom: 24px;
		color: #A3A3A3;
		font-size: 14px;
		line-height: 1;
		text-transform: uppercase;
	}
	&__title-row{
		margin-bottom: 30px;
	}
	&__buttons-row{
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		.go-to-cart{
			right: unset;
			left: 0;
		}
	}
	&__add-to-cart{
		position: relative;
		button{
			width: 110px;
			height: 48px;
		}
	}
	&__notify-button{
		button{
			width: 110px;
			height: 48px;
		}
	}
	&__button{
		width: 270px;
		height: 50px;
		transition: border-color .4s;
		svg{
			margin: -4px 10px 0 0;
		}
		&.btn-primary{
			svg{
				fill: #fff;
			}
		}
		&.btn-alt{
			height: 42px;
		}
		&.add-to-cart{
			height: 50px;
			margin-right: 20px;
		}
		&.sn-add-to-compare{
			color: $color-primary;
			svg{
				fill: $color-primary;
			}
		}
	}
	&__buttons-wrapper{
		display: flex;
		flex-wrap: wrap;
		padding: 10px 0;
	}
	&__short_specs{
		margin: 40px 0 50px;
		&-title{
			margin-bottom: 40px;
			font-size: 20px;
			line-height: 1;
			font-weight: 700;
		}
		&-btn{
			margin-top: 30px;
		}
		.tab-pane__characteristic-row{
			border-bottom: 0 !important;
		}
	}
}



@media (max-width : 1199px) {
	.product-form{
		&__title{
			font-size: 22px;
			&-row{
				margin-bottom: 15px;
			}
		}
		&__vendor-code{
			margin-bottom: 12px;
			font-size: 12px;
		}
		&__short_specs{
			display: none;
			margin: 30px 0;
			&-title{
				margin-bottom: 20px;
			}
		}
	}
}

@media(max-width: 991px){
	.product-form{
		padding: 25px 0 30px;
		&__button{
			&.add-to-cart{
				margin-right: 0;
			}
		}
	}
}

@media(max-width: 575px){
	.product-form{
		&__title{
		}
		&__buttons-row{
			flex-direction: column;
		}
		&__button{
			width: 100%;
			margin: 10px 0 0;
		}
	}
}