.filter-block{
	border-bottom: 4px solid #F1F4F7;
	background-color: #fff;
	&__header{
		display: flex;
		justify-content: space-between;
		position: relative;
		padding: 23px 20px 23px 25px;
		font-size: 13px;
		font-weight: 700;
		line-height: 1.25;
		overflow: hidden;
		transition: color .4s;
		svg{
			width: 12px;
			height: 7px;
			margin-top: 2px;
			flex: 0 0 auto;
			transform: rotate(180deg);
			fill: #757575;
			transition: transform .4s;
		}
		&:before{
			content: '';
			width: 26px;
			height: 26px;
			position: absolute;
			left: -13px;
			top: 16px;
			border: 4px solid $color-primary;
			border-radius: 26px;
		}
		&:hover{
			color: $color-general;
		}
		&.collapsed{
			svg{
				transform: rotate(0deg);
			}
		}
	}
	&__body{
		.custom-control{
			padding: 3px 0 3px 24px;
			margin-bottom: 8px;
			cursor: pointer;
			&-label{
				display: block;
				color: #757575;
				font-size: 15px;
				line-height: 1;
				cursor: pointer;
				transition: color .4s;
				&:before, &:after{
					width: 18px;
					height: 18px;
					top: -3px;
					border-radius: 2px;
				}
				&:before{
					background-color: transparent;
					border: 1px solid #CDCDCD;
				}
				&:after{
				}
			}
			.custom-control-input:checked ~ .custom-control-label::before{
				background-color: $color-general;
				border-color: $color-general;
			}
			input{
				display: none;
			}
			&:hover{
				.custom-control{
					&-label{
						color: $color-text;
					}
				}
			}
		}
		.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{
			color: $color-general;
		}
	}
	&__wrapper{
		padding: 0 20px 29px 25px;
		overflow: hidden;
	}
	&__scroll-content{
		max-height: 210px;
		.os-content{
			padding: 0 15px 0 0 !important;
		}
	}
	&__search_field{
		margin-bottom: 14px;
	}
}