.mobile-menu{
	&, & .sub-menu{
		position: fixed;
		z-index: 1005;
		left: 0;
		top: 60px;
		width: 100%;
		height: calc(100% - 60px);
		overflow-y: scroll;
		background-color: #fff;
		left: 110%;
		background-color: #F1F4F7;
		transition: .3s;
		&.opened{
			left: 0;
		}
		&.no-scroll{
			overflow: hidden;
		}
	}
	.main-menu{
		padding: 11px 0;
		>li>.mobile-menu-item{
			padding: 9px 20px;
			border: 0;
		}
	}

	&-item{
		display: block;
		padding: 16px 20px;
		position: relative;
		line-height: 1;
		border-bottom: 1px solid #D3D3D3;
		&-text{
			font-size: 16px;
			line-height: 1.3;
		}
		.shop-button__count{
			left: unset;
			top: 0;
			bottom: 0;
			right: 15px;
			margin: auto;
		}
		.shop-button__icon{
			margin-top: 0;
			margin-bottom: 3px;
		}
		&-arr{
			width: 13.3px;
		    height: 8.6px;
		    position: absolute;
		    top: 0;
		    bottom: 3px;
		    right: 20px;
		    margin: auto;
		    transform: rotate(-90deg);
		    fill: #D8D8D8;
		}
		&.search-form{
			margin: 0;
			height: auto;
			background-color: #fff;
			input{
				border: 0;
			}
			button{
				left: 20px;
			}
		}
	}
	
	.catalog-link{
		background-color: #fff;
		> a{
			display: flex;
			align-items: center;
			padding-left: 17px;
			position: relative;
			height: 50px;
			color: $color-general;
			font-size: 14px;
			line-height: 1;
			font-weight: 700;
			text-transform: uppercase;
			border-top: 2px solid $color-general;
			border-bottom: 2px solid $color-general;
			>svg{
				width: 16px;
				height: 16px;
				margin: -4px 15px 0 0;
				fill: $color-general;
			}
			>span{
				padding-top: 1px;
			}
		}
	}
	
	&__footer{
		padding: 16px 20px;
		background-color: #fff;
		.header-contacts-item{
			display: flex;
			margin: 0;
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
	}
	.open-child{
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 75px;
		height: 100%;
		svg{
			width: 13.3px;
			height: 8.6px;
			position: absolute;
			top: 0;
			bottom: 3px;
			right: 20px;
			margin: auto;
			transform: rotate(-90deg);
			fill: #D8D8D8;
		}
	}
	.step_back{
		background-color: #DFE5EA;
		.shop-button__icon{
			width: 11.3px;
			height: 6.6px;
			margin-bottom: 3px;
			transform: rotate(90deg);
			fill: #ADB4B9;
		}
		.mobile-menu-item-text{
			color: #ADB4B9;
		}
	}
}