.service-block{
	display: block;
	width: 230px;
	height: 500px;
	flex: 0 0 auto;
	margin-bottom: 20px;
	overflow: hidden;
	background-color: #F1F4F7;
	&-img{
		width: 100%;
		height: 80%;
		object-fit: cover;
		object-position: center;
		transition: transform .4s;
	}
	&-bottom{
		display: flex;
		align-items: center;
		height: 150px;
		padding: 25px 25px 77px;
		position: relative;
		transition: background-color .4s, transform .4s;
		svg{
			position: absolute;
			left: 25px;
			bottom: 30px;
			width: 73px;
			height: 19px;
			transform: rotate(180deg);
			fill: $color-general;
		}
	}
	&-title{
		display: block;
		font-size: 18px;
		line-height: 1.33;
		color: $color-primary;
		font-weight: 700;
		transition: color .4s;
	}
	&:hover{
		.service-block{
			&-title{
				color: #fff;
			}
			&-bottom{
				background-color: $color-primary;
				transform: translateY(-50px);
			}
			&-img{
				transform: translateY(-50px);
			}
		}
	}
}
.services-row{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
@media (max-width: 1199px){
	.service-block{
		height: 340px;
		&-bottom{
			height: 20%;
			padding: 20px;
			svg{
				display: none;
			}
		}
		&:hover{
			.service-block-bottom{
				transform: translateY(0);
			}
		}
	}
}
@media (max-width: 575px){
	.services-row{
		justify-content: center;
	}
}