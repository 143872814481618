.section-title{
	font-size: 46px;
	line-height: 1.3;
	font-weight: 700;
	&-white{
		color: #fff;
	}
}

@media (max-width: 1199px){
	.section-title{
		font-size: 30px;
	}
}