.order-items{
	background-color: #fff;
	&__item{
		display: flex;
		justify-content: space-between;
		&-info{
			max-width: 65%;
		}
		&-price{
			font-size: 18px;
			line-height: 1;
			font-weight: 400;
		}
		&-articul{
			margin-bottom: 5px;
			font-size: 10px;
			line-height: 1;
			letter-spacing: 0.25px;
			text-transform: uppercase;
			color: #A3A3A3;
		}
		&-title{
			font-size: 16px;
			line-height: 1.35;
		}
		&-count{
			color: #757575;
		}
		&:not(:last-child){
	        margin-bottom: 25px;
	    }
	}
	&-top{
		padding: 35px 30px 45px;
		border-bottom: 4px dashed #F1F4F7;
	}
	&__delivery-type{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 20px;
		margin-top: 15px;
		border-top: 1px solid #bcdff1;
		border-bottom: 1px solid #bcdff1;
		background-color: rgba(0, 123, 255, 0.2);
		span{
			color: #616161;
			font-size: 14px;
		}
		b{
			color: #3a372e;
			font-size: 16px;
		}
	}
	&-bottom{
		padding: 38px 30px;
		&-title{
			margin-bottom: 26px;
		}
		.order_total{
			color: $color-primary;
			font-size: 36px;
			line-height: 1;
			span{
				font-size: 26px;
			}
		}
	}
}

@media(max-width: 1199px){
	.order-items{
		&-top{
			padding: 25px 15px;
		}
		&-bottom{
			padding: 25px 15px;
			&-title{
				margin-bottom: 18px;
			}
		}
		&__item{
			&-title{
				font-size: 15px;
			}
		}
	}
}

@media(max-width: 991px){
	.order-items{
		margin: 25px 0 0;
	}
}