.file {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 10px 0 15px;
	&-thumbnail {
		display: inline-block;
		width: 36px;
		height: 46px;
		flex: 0 0 auto;
		margin-top: 3px;
		> svg {
			width: 100%;
			height: 100%;
			fill: $color-primary;
		}
	}
	&-info {
		display: inline-block;
		padding: 1px 0 0 13px;
		.name {
			@include font-p;
			line-height: 22px;
			em {
				font-style: normal;
				border-bottom: 1px solid transparent;
				transition: .4s;
			}
		}
		.size {
			display: block;
			color: #999CA1;
			text-transform: uppercase;
			padding-top: 4px;
			@include font-sign;
		}
	}
	&:hover .file-info {
		.name em{
			color: $color-general;
			border-color: $color-general;
		}
	}
}

@media(max-width: 767px) {
	@import "../media-mixins";
	
	.file {
		padding: 6px 0;
		&-thumbnail {
			min-width: 26px;
			width: 26px;
			height: 32px;
		}
		&-info {
			padding: 0 0 0 10px;
			.name {
				@include font-p;
			}
			.size {
				@include font-sign;
			}
		}
	}
}