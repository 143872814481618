.contacts-page{
	padding: 0;
	position: relative;
	&-title{
		margin-bottom: 57px;
	}
}
.contacts-block {
	position: absolute;
	left: 0;
	top: 64px;
	bottom: -1px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 44.5vw;
	padding: 70px 8vw 100px 0;
	z-index: 1;
	background-color: rgba($color-primary, .8);
	&__map-container {
		height: 620px;
		width: 100%;
	}
}

@media (max-width: 1199px){
	.contacts-page{
		&-title{
			margin-bottom: 30px;
		}
	}
	.contacts-block{
		padding: 30px 15px 20px;
		align-items: flex-start;
	}
}
@media (max-width: 991px){
	.contacts-block{
		padding: 30px 15px 20px;
		&__map-container{
			height: 500px;
		}
	}
}

@media (min-width: 768px){
	.contacts-block{
		&__map-container{
			.ymaps-2-1-74-controls__control{
				position: absolute !important;
				left: unset !important;
				right: 10px !important;
			}
			.ymaps-2-1-74-map-copyrights-promo{
				left: unset;
				right: 0;
				bottom: 25px;
			}
		}
	}
}

@media (max-width: 767px) {
	.contacts-block {
		position: static;
		width: 100vw;
		padding: 30px 15px 20px;
		background-color: $color-primary;
		&__map-container {
			height: 300px;
		}
	}
}