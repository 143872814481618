.sidebar {
    margin: 0 0 30px;
    &-menu{
        &-item{
            padding-left: 44px;
            position: relative;
            &.current-menu-item,&.current-sidebar-item{
                &:before{
                    content: '';
                    width: 24px;
                    height: 2px;
                    position: absolute;
                    left: 0;
                    top: 8px;
                    background-color: $color-primary;
                }
                .sidebar-menu-link{
                    color: $color-primary;
                }
            }
            &:not(:last-child){
                margin-bottom: 24px;
            }
        }
        &-link {
            display: block;
            color: #757575;
            font-size: 15px;
            line-height: 1.4;
            transition: color .4s;
            &:hover{
                color: $color-primary;
            }
        }
    }

}