.shop-square-button{
    width: 45px;
    height: 45px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background-color: #F1F4F7;
    transition: background-color .4s;
    &.compare-button{
        &:hover{
            background-color: #C9D2DC;
        }
    }
    &.cart-button{
        background-color: $color-general;
        &:hover{
            background-color: #E22815;
        }
        .shop-button__icon{
            fill: #fff;
        }
    }
    .shop-button__icon{
        margin-right: 0;
        margin-top: 0;
    }
    &:not(:last-child){
        margin-right: 19px;
    }
}
.shop-button {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    padding: 10px 13px;

    &__title{
        padding-bottom: 2px;
        border-bottom: 2px solid #ddd;
        transition: border-color .4s;
    }

    &__icon {
        fill: $color-primary;
        margin-right: 10px;
        margin-top: -6px;
    }

    &__count {
        display: block;
        width: 19px;
        height: 19px;
        position: absolute;
        left: 75%;
        bottom: 75%;
        padding-top: 6px;
        transform: scale(0);
        color: #fff;
        font-size: 10px;
        line-height: 1;
        font-weight: 700;
        text-align: center;
        background-color: $color-primary;
        border-radius: 50px;
        transition: transform .2s;
        &.filled{
            transform: scale(1);
        }
    }
    &:hover{
        .shop-button__title{
            color: $color-text;
            border-color: transparent !important;
        }
    }
    &-mobile{
        background-color: transparent;
        &.cart-button{
            background-color: transparent;
            .shop-button__icon{
                fill: $color-general;
            }
        }
        &.phone-button{
            fill: $color-primary;
        }
    }
}
@media (max-width: 1199px){
    .shop-square-button{
        width: 30px;
        height: 30px;
        &:not(:last-child){
            margin-right: 5px;
        }
        &.cart-button{
            &:hover{
                background-color: transparent;
            }
        }
    }
    .shop-button{
        &__count{
            left: 60%;
            bottom: 60%;
        }
    }
}