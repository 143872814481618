.cart-item {
    $this: &;
    width: 100%;
    height: 120px;
    margin-bottom: 4px;
    position: relative;
    overflow: hidden;
    will-change: height;
    background-color: #fff;
    &:last-child {
        border-bottom: 0;
    }

    &-link {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        &:hover {
            #{$this}-title {
                color: $color-general;
            }
        }
        &-wrap{
            max-width: 275px;
        }
    }

    &-thumb {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 105px;
        height: 100px;
        object-fit: scale-down;
        object-position: center;
        margin-right: 15px;
        overflow: hidden;

        &_no-photo {
        }

        &__image {
            max-width: 100%;
            max-height: 100%;
        }

        &__icon {
            width: 100%;
            height: 100%;
            padding: 18px;
            fill: #F2F2F2;
        }
    }

    &-articul{
        margin-bottom: 6px;
        font-size: 11px;
        line-height: 1;
        color: #A3A3A3;
        letter-spacing: 0.275px;
        text-transform: uppercase;
    }

    &-title {
        font-size: 16px;
        line-height: 1.37;
        transition: color .4s;
    }

    &-price {
        font-size: 20px;
        line-height: 1;
        color: $color-text;
        &__has-old{
            color: $color-general;
        }
        &-wrap{
            padding-top: 2px;
        }
    }

    &-old-price {
        margin-top: 8px;
        font-size: 18px;
        line-height: 1;
        color: #BABABA;
        text-decoration: line-through;
    }

    &-price_sale{
        color: $color-general;
    }
    
    &-currency{
        font-size: 16px;
    }

    &-delete {
        width: 35px;
        height: 35px;
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        cursor: pointer;

        &::before, &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 15px;
            height: 1px;
            background-color: #C0C0C0;
            margin: auto;
            transition: background-color .4s;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }

        &:hover {
            &::before, &::after {
                background-color: $color-general;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px){
    .cart-item{
        &-link{
            padding-right: 10px;
            &-wrap{
                width: 200px;
                max-width: unset;
            }
        }
    }
}

@media(max-width: 767px) {
    .cart-item {
        height: auto;

        &-link {
            flex-direction: column;
            padding: 0;
        }

        &-thumb {
            margin-right: 0;
            margin-bottom: 10px;
        }

        &-delete {
            height: 35px;
        }
        &-price{
            display: inline-block;
        }
        &-old-price{
            display: inline-block;
        }
    }
}
