.cart {
    &-table {

        &-row {
            display: flex;
            width: 100%;
            height: 100%;
        }

        &-col {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 100%;

            &_price {
                flex: 0 0 auto;
                width: 125px;
            }

            &_count {
                flex: 0 0 auto;
                width: 140px;
            }

            &_total {
                flex: 0 0 auto;
                width: 130px;
            }

            &_delete {
                flex: 0 0 auto;
                width: 35px;
            }
        }
    }

    &-order {
        padding: 35px 30px;
        background-color: #fff;

        &-btn {
            height: 50px;
            width: 100%;
            margin-top: 20px;
        }
    }

    &-text {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1;
        color: $color-text;
    }

    &-total{
        display: inline;
        font-size: 36px;
        line-height: 1;
    }
    &-currency{
        font-size: 26px;
    }
}

@media(max-width: 1199px) {
    .cart {
        &-order {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            &-btn {
                margin-top: 10px;
                margin-left: 0;
            }
        }
    }
}

@media (max-width: 991px){
    .cart{
        &-order{
            margin-top: 20px;
        }
    }
}

@media(max-width: 767px) {
    .cart {
        &-table {
            &-head {
                display: none;
            }

            &-row {
                flex-direction: column;
            }

            &-col {
                flex: 0 1 auto;
                align-items: center;
                width: 100%;
                height: auto;
                padding: 5px 15px;

                &_product {
                    padding: 15px 15px 5px;
                }

                &_delete {
                    padding: 0;
                }
            }
        }
    }
}
