.main-menu {
	width: 100%;
	justify-content: space-between;
    &-wrapper {
    	flex: 1 0 auto;
        padding: 18px 90px 0 50px;
    }
    &-link{
    	padding: 0 !important;
        font-weight: 400;
    	transition: color .4s;
    	svg{
    		width: 8.5px;
    		height: 5px;
    		fill: $color-primary;
    		transition: transform .4s;
    	}
		&:hover{
			color: #848484;
		}
    }
    &-item{
        position: relative;
    	&:hover{
    		.main-menu-link{
    			color: #848484;
				svg{
					transform: rotate(180deg);
				}
    		}
            .main-menu-dropdown{
                display: block;
            }
    	}
        &:after{
            content: '';
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            bottom: -20px;
            height: 20px;
        }
    }
    &-dropdown{
        padding: 14px 0;
        top: calc(100% + 20px);
        margin: 0;
    	border: 0;
    	background: #fff;
		border-radius: 3px;
        box-shadow: 0 3px 5px rgba(#333,.35);
    }
    .dropdown{
        &-item{
            padding: 8px 60px 8px 30px;
            font-size: 16px;
            line-height: 1.37;
            font-weight: 300;
            color: $color-text;
            span{
                border-bottom: 2px solid transparent;
                transition: border-color .4s;
            }
            &:hover{
                background-color: transparent;
                span{
                    border-color: $color-general;
                }
            }
        }
    }
}
