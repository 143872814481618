@font-face{
	font-family: Vremena Grotesk;
	font-display: swap;
	font-weight: 300;
	src: url('../fonts/Vremena Grotesk/vremenagroteskbook.woff2'),
		url('../fonts/Vremena Grotesk/vremenagroteskbook.woff'),
		url('../fonts/Vremena Grotesk/vremenagroteskbook.ttf');
}
@font-face{
	font-family: Vremena Grotesk;
	font-display: swap;
	font-weight: 300;
	font-style: italic;
	src: url('../fonts/Vremena Grotesk/vremenagroteskbookitalic.woff2'),
		url('../fonts/Vremena Grotesk/vremenagroteskbookitalic.woff'),
		url('../fonts/Vremena Grotesk/vremenagroteskbookitalic.ttf');
}
@font-face{
	font-family: Vremena Grotesk;
	font-display: swap;
	font-weight: 400;
	src: url('../fonts/Vremena Grotesk/vremenagrotesk.woff2'),
		url('../fonts/Vremena Grotesk/vremenagrotesk.woff'),
		url('../fonts/Vremena Grotesk/vremenagrotesk.ttf');
}
@font-face{
	font-family: Vremena Grotesk;
	font-display: swap;
	font-style: italic;
	src: url('../fonts/Vremena Grotesk/vremenagroteskitalic.woff2'),
		url('../fonts/Vremena Grotesk/vremenagroteskitalic.woff'),
		url('../fonts/Vremena Grotesk/vremenagroteskitalic.ttf');
}
@font-face{
	font-family: Vremena Grotesk;
	font-display: swap;
	font-weight: 500;
	src: url('../fonts/Vremena Grotesk/vremenagroteskmedium.woff2'),
		url('../fonts/Vremena Grotesk/vremenagroteskmedium.woff'),
		url('../fonts/Vremena Grotesk/vremenagroteskmedium.ttf');
}
@font-face{
	font-family: Vremena Grotesk;
	font-display: swap;
	font-weight: 500;
	font-style: italic;
	src: url('../fonts/Vremena Grotesk/vremenagroteskmediumitalic.woff2'),
		url('../fonts/Vremena Grotesk/vremenagroteskmediumitalic.woff'),
		url('../fonts/Vremena Grotesk/vremenagroteskmediumitalic.ttf');
}
@font-face{
	font-family: Vremena Grotesk;
	font-display: swap;
	font-weight: 700;
	src: url('../fonts/Vremena Grotesk/vremenagroteskbold.woff2'),
		url('../fonts/Vremena Grotesk/vremenagroteskbold.woff'),
		url('../fonts/Vremena Grotesk/vremenagroteskbold.ttf');
}
@font-face{
	font-family: Vremena Grotesk;
	font-display: swap;
	font-weight: 700;
	font-style: italic;
	src: url('../fonts/Vremena Grotesk/vremenagroteskbolditalic.woff2'),
		url('../fonts/Vremena Grotesk/vremenagroteskbolditalic.woff'),
		url('../fonts/Vremena Grotesk/vremenagroteskbolditalic.ttf');
}