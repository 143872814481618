.section-popular-products{
	padding: 70px 0 50px;
	&-title{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 60px;
	}
	&-link{
		padding-bottom: 2px;
		color: #97A1AA;
		font-size: 14px;
		line-height: 1;
		letter-spacing: 1px;
		text-transform: uppercase;
		border-bottom: 2px solid $color-primary;
		transition: border-color .4s;
		&:hover{
			color: #97A1AA;
			border-color: transparent;
		}
	}
}
.products-slider{
	display: flex;
	overflow: hidden;
	&-slide{
		display: flex !important;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 97px;
		width: 100%;
	}
	>.products-slider-slide{
		margin-bottom: 52px;
		&:not(:first-child){
			display: none !important;
		}
	}
	&-arrow{
		border: 0;
		background-color: transparent;
		cursor: pointer;
		svg{
			width: 27px;
			height: 17px;
			fill: #D8D8D8;
			transition: fill .4s;
		}
		&:hover{
			svg{
				fill: $color-primary;
			}
		}
		&.prev{
			svg{
				transform: rotate(90deg);
			}
		}
		&.next{
			svg{
				transform: rotate(-90deg);
			}
		}
	}
	&-controls{
		display: flex;
		justify-content: space-between;
		align-items: center;
		visibility: hidden;
		&.active{
			visibility: visible;
		}
		&-wrap{
			display: block;
			justify-content: space-between;
			align-items: center;
		}
	}
	&-counter{
		margin: 4px 15px 0;
		font-size: 24px;
		line-height: 1;
	}
	&-dots-container{
		flex: 1 0 auto;
		min-height: 1px;
		.slick-dots{
			display: flex;
			li{
				height: 3px;
				flex: 1 0 auto;
				background-color: #C1CEDB;
				button{
					display: none;
				}
				&.slick-active{
					background-color: $color-text;
				}
			}
		}
	}
}

@media (max-width: 1199px){
	.section-popular-products{
		&-title{
			font-size: 20px;
			margin-bottom: 20px;
		}
	}
}

@media (max-width: 1199px){
	.section-popular-products{
		padding: 23px 0 32px;
	}
	.products-slider{
		&-slide{
			justify-content: space-around;
			margin-bottom: 22px;
		}
		.product-card{
			max-width: 390px;
		}
	}
}

@media (max-width: 991px){
	.products-slider{
		.product-card{
			max-width: 325px;
		}
		&-dots-container{
			display: none;
		}
		&-counter{
			display: none;
		}
		&-arrow{
			&.prev{
				margin: 2px 20px 0 0;
			}
		}
		&-controls{
			&-wrap{
				display: flex;
			}
		}
	}
}

@media (max-width: 767px){
	.products-slider{
		.product-card{
			max-width: 290px;
		}
	}
}