@mixin font-h1 {
	font-size: 22px;
	line-height: 1.27;
}

@mixin font-h2 {
	font-size: 18px;
}

@mixin font-h3 {
	font-size: 16px;
}

@mixin font-p {
	font-size: 15px;
	line-height: 1.47;
}

@mixin font-sign {
	font-size: 11px;
}