.product-card{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 30px;
    height: calc(100% - 30px);
    width: 270px;
    min-height: 380px;
    position: relative;
    background-color: #fff;
    transition: box-shadow .4s;
    &-top{

    }
    &-body{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 0 auto;
        padding: 37px 20px 34px;
        position: relative;
        z-index: 2;
        background-color: #fff;
        border-bottom: 3px solid $color-primary;
        transition: border-color .4s;
    }
    &-img{
        width: 100%;
        height: 220px;
        padding: 20px 20px 0;
        margin-bottom: 14px;
        object-fit: scale-down;
        object-position: center;
    }
    &-no-photo{
        padding: 45px;
        fill: #F2F2F2;
    }
    &-articul{
        display: block;
        position: absolute;
        top: 20px;
        margin-bottom: 6px;
        font-size: 11px;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.275px;
        color: #a3a3a3;
    }
    &-name{
        display: block;
        max-height: 42px;
        height: 42px;
        overflow: hidden;
        margin-bottom: 21px;
        font-size: 16px;
        line-height: 1.37;
    }
    &-price{
        font-size: 30px;
        line-height: 1;
        .rub{
            font-size: 22px;
        }
        &.has-old{
            padding-right: 10px;
            color: $color-general;
        }
    }
    &-old-price{
        font-size: 18px;
        line-height: 1;
        text-decoration: line-through;
        color: #bababa;
    }
    &-action-badge{
        width: 48px;
        height: 48px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 1;
    }
    .shop-square-button{
        display: inline-flex;
        width: 50px;
        height: 50px;
        border: 0;
        cursor: pointer;
    }
    &-bottom{
        display: block;
        width: 100%;
        padding: 16px 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: #fff;
        transition: transform .4s, box-shadow .4s;
        &-form{
            display: flex;
            justify-content: space-between;
        }
    }
    &-btn{
        width: 163px;
        height: 50px;
        svg{
            width: 18.4px;
            height: 17.9px;
            margin: 0 7px 5px 0;
            fill: #fff;
        }
        &.added{
            background-color: $color-primary;
        }
    }
    &:hover{
        color: $color-text;
        box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.089);
        .product-card{
            &-bottom{
                transform: translateY(100%);
                box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.089);
            }
            &-body{
                border-color: #F1F4F7;
            }
        }
    }
    &-remove,&-restore{
        width: 30px;
        height: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
        border: 0;
        border-radius: 3px;
        background-color: #F1F4F7;
        cursor: pointer;
        box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
        transition: background-color .4s;
        svg{
            width: 12px;
            height: 12px;
            fill: $color-primary;
            transition: fill .4s;
        }
        &:hover{
            background-color: #C9D2DC;
        }
    }
    &-remove{
    }
    &-restore{
        display: none;
    }
    &.removed{
        opacity: .8;
        .product-card{
            &-remove{
                display: none;
            }
            &-restore{
                display: block;
            }
        }
    }
    .compare-button.active{
        background-color: $color-primary;
        .shop-button__icon{
            fill: #fff;
        }
    }
}

@media(min-width: 1200px){
    .product-card{
        &-name{
            &:first-of-type{
                // margin-top: 16px;
            }
        }
    }
}

@media(max-width: 1199px){
    .product-card{
        width: 100%;
        height: auto;
        min-height: unset;
        flex-direction: row;
        margin-bottom: 10px;
        &-top{
            padding: 15px 0 0 10px;
        }
        &-body{
            flex: 1 0;
            padding: 19px 14px 16px 10px;
            border-bottom: 0;
        }
        &-img{
            width: 100px;
            height: 100px;
            margin-bottom: 0;
            padding: 0;
        }
        &-bottom{
            position: static;
            padding: 8px 15px;
            margin-top: 12px;
            
            border-top: 3px solid #F1F4F7;
        }
        &-articul{
            font-size: 9px;
            position: static;
        }
        &-name{
            height: auto;
            font-size: 15px;
            line-height: 1.2;
        }
        &-price{
            display: block;
            font-size: 22px;
        }
        &-old-price{
            display: block;
            font-size: 13px;
        }
        &-action-badge{
            width: 32px;
            height: 32px;
            top: 10px;
            right: 10px;
        }
        &-btn{
            height: 36px;
            width: auto;
            flex: 1 0 auto;
            margin-right: 10px;
        }
        .shop-square-button{
            width: 36px;
            height: 36px;
        }
        &-remove,&-restore{
            width: 25px;
            height: 25px;
            right: unset;
            top: 15px;
            left: 80px;
        }
        &:hover{
            .product-card-bottom{
                transform: translateY(0);
            }
        }
    }
}
@media (max-width: 767px){
    .product-card{
        &-body{
            max-width: 55%;
        }
    }
}