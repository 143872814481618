.product{
	&__top-info {
		padding-bottom: 58px;
	}
	&-tabs{
		background-color: #fff;
		.nav{
			&-tabs{
				padding: 26px 40px 0;
				flex-wrap: nowrap;
				overflow: auto;
				border: 0;
				border-bottom: 4px solid #F1F4F7;
			}
			&-link{
				padding: 0 0 18px;
				color: $color-text !important;
				font-size: 13px;
				line-height: 1;
				letter-spacing: 0.579091px;
				text-transform: uppercase;
				border: 0;
				border-radius: 0;
				border-bottom: 3px solid transparent;
				transition: color .4s, border-color .4s;
				&.active{
					border-color: $color-general !important;
				}
				&:hover{
					color: $color-primary !important;
					border-color: transparent;
				}
			}
			&-item{
				margin: 0;
				&:not(:last-child){
					margin-right: 40px;
				}
			}
		}
	}
}


@media (max-width: 1199px) {
	.product{
		&__top-info {
			padding: 0 0 25px;
		}
		&-tabs{
			.nav{
				&-tabs{
					padding: 15px 15px 0;
				}
			}
		}
	}
}

@media (max-width: 991px){
	.product-tabs{
		margin-bottom: 30px;
	}
}
@media (max-width: 767px){
	.product-tabs{
		.nav{
			&-tabs{
				padding: 15px;
			}
			&-item{
				width: 100%;
				padding-right: 20px;
				&:not(:last-child){
					margin: 0;		
				}
			}
		}
	}
}