.tab-pane{
	padding: 28px 40px 40px;
	&__characteristic-row{
		display: flex;
		align-items: flex-start;
		padding: 10px 0 7px;
		&:not(:last-child){
			border-bottom: 1px solid #D8D8D8;
		}
		&-title{
			width: 40%;
			flex: 0 0 auto;
			padding-right: 35px;
			font-size: 15px;
			line-height: 1.33;
		}
		&-value{
			color: #757575;
			font-size: 16px;
			line-height: 1.25;
		}
	}
}


@media (max-width : 1199px) {
	.tab-pane{
		padding: 20px 15px 25px;
	}
}

@media (max-width : 575px) {
	.tab-pane{
		&__characteristic-row{
			flex-direction: column;
			padding: 8px 0 0;
			&-title{
				width: 100%;
				padding-right: 0;
			}
			&-value{
				font-size: 14px;
			}
		}
	}
}